import React, { Component } from "react";
import { Navbar, Nav, Image, NavDropdown } from "react-bootstrap";
import "./CustomNavbar.css";
import CaregemLogo from "../assets/careGem-transparent-logo.svg";

export default class CustomNavbar extends Component {
  render() {
    return (
      <Navbar
        className="custom-navbar"
        sticky="top"
        expand="lg"
        default
        collapseOnSelect
      >
        <Navbar.Brand href="/">
          <span className="brand-title">
            <a href="/" className="brand">
              <img
                src={CaregemLogo}
                width="180"
                alt="Caregem logo"
                className="d-inline-block align-top"
              />
            </a>
          </span>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto" />
          <Nav className="justify-content-end">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="About" id="basic-nav-dropdown">
              <NavDropdown.Item href="/#challenges">
                Challenges of Kidney Disease
              </NavDropdown.Item>
              <NavDropdown.Item href="/#address-it">
                Tackling CKD
              </NavDropdown.Item>
              <NavDropdown.Item href="/#mission">
                Mission and Vision
              </NavDropdown.Item>
              <NavDropdown.Item href="/#team">Team</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Partners" id="basic-nav-dropdown">
              <NavDropdown.Item href="/#health-plans">
                Health Plans
              </NavDropdown.Item>
              <NavDropdown.Item href="/#integrated-care">
                Integrated Care Network/ACO
              </NavDropdown.Item>
              <NavDropdown.Item href="/#esco">ESCO</NavDropdown.Item>
              <NavDropdown.Item href="/#providers">Providers</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Patients" id="basic-nav-dropdown">
              <NavDropdown.Item href="/#ckd">
                Chronic Kidney Disease
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/#contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

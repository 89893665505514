import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Navbar from "./components/CustomNavbar";
import Home from "./components/Home";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/privacy-policy/PrivacyPolicy";
import PatientPolicy from "./components/privacy-policy/PatientPolicy";
import PatientTerms from "./components/privacy-policy/PatientTerms";
import PatientHippa from "./components/privacy-policy/PatientHIPPA";
import ProviderTerms from "./components/privacy-policy/ProviderTerms";
import ProviderPolicy from "./components/privacy-policy/ProviderPolicy";
import LegalPage from "./components/LegalPage";

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Navbar />
          <Route exact path="/" component={Home} />
          <Route exact path="/legal" component={LegalPage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/legal/patient/terms" component={PatientTerms} />
          <Route exact path="/legal/patient/policy" component={PatientPolicy} />
          <Route exact path="/legal/patient/HIPAA" component={PatientHippa} />
          <Route exact path="/legal/provider/terms" component={ProviderTerms} />
          <Route
            exact
            path="/legal/provider/policy"
            component={ProviderPolicy}
          />
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;

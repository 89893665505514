import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import "./PrivacyPolicy.css";

export default class ProviderTerms extends Component {
  globalVariable = `<p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 11px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 21px;">Terms and Conditions (for Providers / Health Care Entities)</span></strong></p>

  <p class="MsoNormal" style="line-height: 115%; margin: 22px 22px 0px 13px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Please read these Terms and
  Conditions ("Terms", "Terms and Conditions", “Agreement”)
  carefully before accessing, downloading or using the CareGem mobile application
  (“Mobile App”), the CareGem web based end-user<span>&nbsp;
  </span>and administrative enterprise platforms, the </span><span lang="EN-US"><a href="http://www.caregem.com" style="color: blue; text-decoration: underline;"><span style="line-height: 115%; font-family: Times, serif; font-size: 14px;">www.caregem.com</span></a></span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;"> websites, or webpages (together, or
  individually, the "Service") </span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">operated</span><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;"> by </span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; font-size: 14px;">Althea.ai</span><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; font-size: 14px;"><span style="color: black; font-family: Arial, Helvetica, sans-serif;"> Inc. and its affiliates (“Company”,
  "us", "we", or "our"). </span></span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 13px 15px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">By accessing,
  downloading or using the Service, you and/or any entity for which you act on
  behalf of (the person or entity to use the Service, “Subscriber”) are agreeing
  to be bound by the terms and conditions of this Agreement. If Subscriber is an
  entity, the term Subscriber shall include Subscriber, and all of Subscriber’s
  affiliates, employees, representatives, contractors, and users of the Service.
  The agreement to these terms by an employee, consultant or contractor acting on
  behalf of an entity will be deemed to be the agreement of that entity. Any such
  employee, consultant or contractor hereby represents and warrants that he, she
  or it has authority, or has been provided authority, to bind the entity to the
  terms and conditions of this Agreement. Your access to and use of the Service
  is conditioned upon your acceptance of and compliance with these Terms. If
  Subscriber disagrees with any part of the terms of this Agreement,<span>&nbsp; </span>Subscriber may not access, download or use
  the Service or and must immediately remove and<span>&nbsp;
  </span>delete the Service if Subscriber has already downloaded this. These
  Terms apply to all visitors, users and others who wish to access or use the
  Service. Company’s acceptance of use by any Subscriber is expressly conditional
  upon Subscriber’s acceptance of these terms to the exclusion of all other
  terms. The validity and enforceability of this Agreement does not require
  Subscriber’s written approval. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 13px 11px 0px 13px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Notwithstanding anything to the
  contrary, if Subscriber and Company have both executed a written Subscriber
  Agreement which governs access to or use of the Service, then the terms of the
  Subscriber Agreement shall govern and control to the extent there is a direct
  conflict between the terms of this Agreement and the terms of the Subscriber
  Agreement. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 12px 45px 0px 14px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(44, 63, 81); font-size: 14px;">Each of Subscriber
  and Company may be referred to herein as a “Party,” and together as the
  “Parties.” </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 13px 0px 0px 15px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">License </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; margin: 0px 41px 0px 14px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Company grants Subscriber a
  revocable, non-exclusive, non-transferable, limited license to download,
  install and use the </span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(86, 84, 89); font-size: 14px;">Service
  </span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">strictly in accordance
  with the terms of this Agreement. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Restrictions </span></strong></p>
  
  <p class="MsoNormal" style="margin: 0px 0px 0px 14px; line-height: 115%; font-family: Arial, sans-serif; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Subscriber agrees not to do, or
  permit others to do any of the following: </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 11px 25px 0px 38px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">a) copy, license,
  resell, sell, rent, lease, sublicense, assign, distribute, transmit, host,
  outsource, disclose, transfer or make the Service available to any third party;
  </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 31px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">b) modify, adapt, make
  derivative works of, disassemble, decrypt, reverse compile, hack or reverse
  engineer, or otherwise attempt to derive the source code for any part of the
  Service. c) merge the Service with other software </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 39px 0px 14px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">d) remove, alter or obscure any
  proprietary notice (including any notice of copyright or trademark) of Company
  or its affiliates, partners, suppliers or the licensors of the Service; e) use
  or modify another website so as to falsely imply that it is associated with
  CareGem; f) use the Service in contravention of any applicable laws, or for any
  illegal or unauthorized purpose or beyond the scope of the Service’s expected
  use; </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 17px 0px 14px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">g) use the Services to transmit any
  content that may be deemed unlawful, threatening, racist, abusive, libelous,
  pornographic, vulgar, defamatory, obscene, or otherwise inappropriate,
  including any messages constituting or encouraging criminal conduct; content
  that violates or infringes on Company’s legal rights or copyrights, or the
  legal rights or copyrights of others.</span></p>
  
  <p class="MsoNormal" style="margin: 0px; line-height: 115%; font-family: Arial, Helvetica, sans-serif; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;">&nbsp;</span><br></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 24px 0px 38px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">h) intentionally
  interfere with the operation of the Service or with any other person’s use of
  the Service; </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 25px 0px 37px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">i) intentionally
  transmit any viruses, bugs, worms, or any other computer code of a destructive
  nature or that may harm a network, computer, server, hardware, software or
  telephone equipment using the Service; </span></p>
  
  <p class="MsoNormal" style="margin: 0px 0px 0px 12px; line-height: 115%; font-family: Arial, sans-serif; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">j) intentionally gain unauthorized
  access to the Service; </span></p>
  
  <p class="MsoNormal" style="margin: 0px 0px 0px 13px; line-height: 115%; font-family: Arial, sans-serif; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">k) breach, or attempt to breach, the
  security of the Service; </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 54px 0px 38px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">l) use the Service in
  excess of or beyond the maximum number of registered users and/or devices that
  Subscriber has agreed to; </span></p>
  
  <p class="MsoNormal" style="margin: 0px 0px 0px 13px; line-height: 115%; font-family: Arial, sans-serif; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">m) impose an unreasonable burden on
  the Service or network </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 28px 0px 37px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">n) use any data
  mining, robots, or similar data gathering and extraction methods in connection
  with the Service </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 37px 0px 38px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">o) solicit any
  activity, unlawful or otherwise, that infringes Company’s rights or the rights
  of any other party; </span></p>
  
  <p class="MsoNormal" style="margin: 0px 0px 0px 13px; line-height: 115%; font-family: Arial, sans-serif; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">p) enable others to violate any of
  these terms and conditions; </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 41px 0px 38px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">q) use the Service to
  create or transmit unwanted email, junk email, bulk email, promotions, spam or
  content that includes any advertising </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 40px 0px 37px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">r) violate anyone
  else’s legal rights (e.g. privacy rights) or any applicable laws (e.g. HIPAA,
  copyright laws) while using the Service; </span></p>
  
  <p class="MsoNormal" style="margin: 0px 0px 0px 14px; line-height: 115%; font-family: Arial, sans-serif; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">s) use or export the Service in
  violation of U.S. export laws and regulations; </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 8px 15px 0px 12px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">A violation of any of
  the Terms by Subscriber will result in the termination of Subscriber’s CareGem
  account and Subscriber’s ability to access or use the Service. In addition,
  Company reserves the right to refuse Service to anyone for any reason at any time.
  Company may (but has no obligation to) restrict accounts containing content
  that Company determines (in its sole discretion) is unlawful, offensive,
  threatening, libelous, defamatory, obscene or otherwise objectionable. While
  Company prohibits certain conduct and content, Subscriber understands and
  agrees that Company is not responsible for the content transmitted between
  CareGem users. Notwithstanding Company’s governing terms and conditions,
  Subscriber agrees to assume the risk of exposure to offensive or unlawful
  content and to use the Services at Subscriber’s own risk. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Subscriber’s
  Suggestions </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 30px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Any feedback,
  comments, ideas, improvements or suggestions (collectively,
  "Suggestions") provided by Subscriber, including Subscriber’s
  employees, contractors or other agents, to Company with respect to the Service
  shall remain the sole and exclusive property of Company, and deemed
  Confidential Information (as defined below) of Company, and Subscriber hereby
  assigns the same to Company </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 13px 50px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Company shall be free
  to use, copy, modify, publish, or redistribute the Suggestions for any purpose
  and in any way without any credit or any compensation to Subscriber. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Modifications
  to Service </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 30px 0px 14px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Company reserves the right to
  modify, suspend or discontinue, temporarily or permanently, the Service or any
  service to which it connects, with or without notice and without liability of
  Company to Subscriber. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Updates to
  Service </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 13px 0px 14px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Company may from time
  to time provide enhancements or improvements to the features/functionality of
  the Service, which may include patches, bug fixes, updates, upgrades and other
  modifications ("Updates"). </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 13px 13px 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Updates may modify or delete certain
  features and/or functionalities of the Service. Subscriber agrees that Company
  has no obligation to (i) provide any Updates, or (ii) continue to provide or
  enable any particular features and/or functionalities of the Service to
  Subscriber. By using the Service, Subscriber grants Company permission to send
  all Subscriber end users messages regarding the Service, its features, service
  alerts, and network activity. Notwithstanding the foregoing, it is Subscriber’s
  responsibility to register for updates from, and/or regularly check, Company’s
  webpages.</span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 27px 0px 14px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; font-size: 14px;">S<span style="color: black;">ubscriber further
  agrees that all Updates will be (i) deemed to constitute an integral part of
  the Service, and (ii) subject to the terms and conditions of this Agreement. </span></span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 24px 0px 14px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Subscriber’s continued
  use of the Service after such updates will constitute its acceptance of the
  changes. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Third-Party
  Services </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 25px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">The Service may
  display, include or make available third-party content (including data,
  information, applications and other products services) or provide links to
  third-party websites or services ("Third-Party Services"). </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 13px 19px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Subscriber
  acknowledges and agrees that Company shall not be responsible for any
  Third-Party Services, including their accuracy, completeness, timeliness,
  validity, copyright compliance, legality, decency, quality or any other aspect
  thereof. Company does not assume and shall not have any liability or
  responsibility to Subscriber or any other person or entity for any Third-Party
  Services. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 13px 38px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Third-Party Services
  and links thereto are provided solely as a convenience to Subscriber and
  Subscriber accesses and uses them entirely at Subscriber’s own risk and subject
  to such third parties' terms and conditions. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Content </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 32px 0px 13px; text-indent: 1px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; font-size: 14px;">The <span style="color: black;">Service
  allows Subscriber to post, link, store, share and otherwise make available
  certain information, text, graphics, photos, or other material
  ("Content"). Subscriber acknowledges responsibility for; (i) Content
  posted through the Service, including its legality, reliability, and
  appropriateness; and (ii) ensuring the Content does not violate anyone else’s
  legal rights (e.g. privacy rights, publicity rights, copyrights, contract
  rights or any other rights) or any applicable laws (e.g. HIPAA, copyright laws)
  while using the Service. </span></span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 13px 39px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Subscriber agrees that
  by posting Content using the Service you grant Company the right and license to
  use the Content to improve the Service, to the extent permitted by applicable
  law. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Privacy
  Policy </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 15px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Company collects,
  stores, maintains, and shares information about Subscriber’s end users in
  accordance with its Privacy Policy. By accepting this Agreement, Subscriber
  acknowledges that Subscriber hereby agrees and consents to the terms and
  conditions of our Privacy Policy. The terms and conditions set forth in
  Company’s Privacy Policy may be changed by Company in its sole discretion and
  those changes become effective upon posting. It is Subscriber’s responsibility
  to review the Privacy Policy for revisions thereto. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Term and
  Termination </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 24px 0px 13px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">This Agreement shall be effective as
  of the date Subscriber accepts the terms herein or first accesses, downloads or
  uses the Service (the “Effective Date”) and shall remain in effect for so long
  as Subscriber uses or accesses the Service (the “Agreement Term”). The terms
  herein that contemplate obligations after the Agreement Term, including but not
  limited to Indemnification, Disclaimer, Limitation of Liability, Severability,
  and Governing Law and Confidentiality, shall survive termination. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 13px 30px 0px 13px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">This Agreement shall remain in
  effect until terminated by Subscriber or Company. Company may, in its sole
  discretion, at any time and for any or no reason, suspend or terminate this
  Agreement with or without prior notice. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 13px 13px 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">This Agreement will terminate
  immediately, without prior notice from Company, in the event that Subscriber
  fails to comply with any provision of this Agreement. Subscriber may also
  terminate this Agreement by deleting the Service software and all copies thereof
  from Subscriber’s mobile device or from Subscriber’s computer. </span></p>
  
  <p class="MsoNormal" style="margin: 0px; line-height: 115%; font-family: Arial, Helvetica, sans-serif; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;">&nbsp;</span><br></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 29px 0px 14px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Upon termination of
  this Agreement Term, Subscriber shall no longer be permitted to use or access
  the Service, and Subscriber shall cease all further use of the Service and
  delete all copies of the Service software from Subscriber’s mobile device or
  computer. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 19px 0px 14px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Termination of this
  Agreement will not limit any of Company's rights or remedies at law or in
  equity in case of breach by Subscriber (during the term of this Agreement) of
  any of Subscriber’s obligations under the present Agreement. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 14px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">SMS Messages </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 12px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Use of Service
  involves transmission of SMS messages for alerts and collaboration. Message
  and<span>&nbsp; </span>data rates may apply, for which
  Subscriber or the End User shall be solely responsible. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Intellectual
  Property </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">The Service, including
  without limitation all copyrights, patents, trademarks, trade secrets and other
  intellectual property rights are, and shall remain, the sole and exclusive
  property of Company. Subscriber acknowledges that the Service, and its respective
  structures, organization, documentation, software and source code constitute
  valuable intellectual property of Company and are owned solely by Company.
  Company does not grant and Subscriber acknowledges that it shall have no right,
  license or interest in any of the patents, copyrights, trademarks, or trade
  secrets owned, used or claimed now or in the future by Company. No title to or
  ownership of the intellectual property contained in any part of Service, the
  software, as well as all enhancements, updates, modifications, local versions
  or any derivatives of the Service or the software, or any and all intellectual
  property and proprietary rights therein, or Company’s confidential information
  is transferred to Subscriber. There are no implied licenses granted by Company
  under this Agreement .</span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 9px 17px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;">“CareGem” and other
  CareGem and Company graphics, logos, designs, page headers, button icons,
  scripts and service names are trademarks of Company. Company’s trademarks and
  trade dress, as well as third party trademarks, logos and service marks used in
  conjunction with the Service, may not be used in connection with any other
  product or service in any manner that is likely to cause confusion and may not
  be copied, imitated, or used, in whole or in part, without the prior written
  permission of Company</span><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: rgb(86, 84, 89); font-size: 14px;">. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 10px 15px 0px 13px; text-indent: 1px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: &quot;Arial Narrow&quot;, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Organizational
  Accounts, Support, and Implementation </span></strong><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;">All organizations with more than 30 users may enter into a
  paid Subscription Agreement with Company. A paid subscription to CareGem
  entitles Subscriber to the level of support included in Subscriber’s
  subscription package, which shall include, at a minimum, standard CareGem
  customer support via email and standard customer training, including a self
  onboarding email and limited implementation email support thereafter.
  Additional support, implementation and professional services may be purchased
  by Subscriber. All such additional services delivered by Company shall be
  subject to the terms and conditions of this Agreement. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 11px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Multi-Organizational
  Users </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 16px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Subscriber
  acknowledges that some of its authorized end users may desire to work with
  other healthcare organizations and that the authorized end users of other
  healthcare organizations may desire to work with Subscriber’s end users (each
  such authorized end user shall be referred to as a “Multi-Organizational
  User”). In order to facilitate use of the Services for Multi-Organizational
  Users, and notwithstanding anything to the contrary in any other document,
  Subscriber gives Company permission to share the name, mobile phone number,
  email address, job title and department of Multi-Organizational Users (“Contact
  Details”) with applicable organizations, including Subscriber, and Subscriber
  shall be required to treat such Contact Details in the same manner it treats
  other Confidential Information. </span></p>
  
  <p class="MsoNormal" style="margin: 0px; line-height: 115%; font-family: Arial, Helvetica, sans-serif; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;">&nbsp;</span><br></p>
  
  <p class="MsoNormal" style="margin: 0px 0px 0px 14px; line-height: 115%; font-family: Arial, sans-serif; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Indemnification
  </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 14px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Subscriber agrees to
  be solely liable for conduct, acts and omissions of Subscriber, including<span>&nbsp; </span>without limitation all of its employees,
  affiliates, consultants, service providers, and users.<span>&nbsp; </span>Subscriber agrees to indemnify and hold
  Company and its parents, subsidiaries, affiliates, officers, shareholders,
  directors, employees, agents, predecessors, successors in interest,
  partners<span>&nbsp; </span>and licensors (if any) harmless
  from any claim or demand, including reasonable attorneys' fees,<span>&nbsp; </span>due to or arising out of Subscriber’s: (i)
  use and access of the Service, including access to<span>&nbsp; </span>Service by anyone using Subscriber’s account
  and password; (ii) content posted on Service; (iii) violation of any right of a
  third party; or (iv) violation of this Agreement or any law or regulation </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Confidentiality
  </span></strong></p>
  
  <p class="MsoNormal" style="margin: 0px 0px 0px 12px; line-height: 115%; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="font-family: Times, serif; color: rgb(44, 63, 81);">“</span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Confidential Information” means any non-public data, information
  and other materials regarding<span>&nbsp; </span>the
  products, software, services, prices and discounts, or business of a </span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; font-size: 14px;">P<span style="color: black;">arty (and/or of third
  parties,<span>&nbsp; </span>to the extent a party is bound
  to protect the confidentiality of any third ’parties’ information)<span>&nbsp; </span>provided by a </span>P<span style="color: black;">arty, its employees, contractors or affiliates (“Disclosing Party”) to
  the other </span>P<span style="color: black;">arty<span>&nbsp; </span>(“Receiving Party”) where such information is
  marked or otherwise communicated as being<span>&nbsp;
  </span>“proprietary” or “confidential” or the like, or where such information
  should, by its nature, be<span>&nbsp; </span>reasonably
  considered to be confidential and/or proprietary. The </span>P<span style="color: black;">arties agree, without limiting the<span>&nbsp; </span>foregoing, that any performance and security
  data, product roadmaps, source code, benchmark<span>&nbsp;
  </span>results, and technical information relating to the Services, including
  pricing information, shall be<span>&nbsp; </span>deemed the
  Confidential Information of Company. Notwithstanding the foregoing,
  Confidential<span>&nbsp; </span>Information shall not
  include information which: (i) is already known to the Receiving Party<span>&nbsp; </span>without the obligations of confidentiality
  prior to disclosure by the Disclosing Party; (ii) becomes<span>&nbsp; </span>publicly available without fault of the
  Receiving Party; (iii) is rightfully obtained by the Receiving<span>&nbsp; </span>Party from a third party without restriction
  as to disclosure, or is approved for release by written<span>&nbsp; </span>authorization of the Disclosing Party; (iv)
  is verifiably a</span>nd </span><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; font-size: 14px;"><span style="color: black; font-family: Arial, Helvetica, sans-serif;">independently developed or
  created by the Receiving<span>&nbsp; </span>Party without
  use of the Disclosing Party’s Confidential Information; or (v) is required to
  be<span>&nbsp; </span>disclosed by law or governmental
  regulation, provided that the Receiving Party provides<span>&nbsp; </span>reasonable notice to Disclosing Party of such
  required disclosure to the extent allowed by<span>&nbsp;
  </span>applicable law, and reasonably cooperates with the Disclosing Party in
  limiting such disclosure.<span>&nbsp; </span>Except as
  expressly authorized herein, the Receiving Party shall: (i) use the
  Confidential<span>&nbsp; </span>Information of the
  Disclosing Party only to perform hereunder or exercise rights granted to
  it<span>&nbsp; </span>hereunder; and (ii) treat all
  Confidential Information of the Disclosing Party in the same manner as<span>&nbsp; </span>it treats its own similar proprietary
  information, but in no case will the degree of care be less than<span>&nbsp; </span>reasonable care. </span></span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Equitable
  Relief </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 0px 54px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">The </span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; font-size: 14px;">P</span><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; font-size: 14px;"><span style="color: black; font-family: Arial, Helvetica, sans-serif;">arties agree that a
  material breach of this Agreement adversely affecting Company’s<span>&nbsp; </span>intellectual property rights in the Service
  or the Confidential Information may cause<span>&nbsp;
  </span>irreparable injury to Company for which monetary damages would not be an
  adequate<span>&nbsp; </span>remedy and Company shall be
  entitled to equitable relief (without a requirement to post a<span>&nbsp; </span>bond) in addition to any remedies it may have
  hereunder or at law. </span></span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 11px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Subscriber
  Acceptance </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 13px; text-indent: 1px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Subscriber represents and warrants
  that Subscriber fully understands and acknowledges that: (i) the Service is not
  intended as a substitute for any of Subscriber’s current processes and systems
  of promptly administering and safeguarding medical treatment; (ii) the Service
  is dependent upon factors outside the control of Company, including but not
  limited to, the operation of third party network services and hardware; (iii)
  the Service is not intended to deliver critical, emergency messages between
  users; (iv) the Service is not expected to perform at the same level of
  performance and/or reliability required of devices used in the delivery of
  critical medical care environments; and (v) there may be occasional
  communication failures or delays in the delivery or receipt of properly sent
  CareGem messages. </span></p>
  
  <p class="MsoNormal" style="margin: 0px; line-height: 115%; font-family: Arial, Helvetica, sans-serif; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;">&nbsp;</span><br></p>
  
  <p class="MsoNormal" style="margin: 0px 0px 0px 14px; line-height: 115%; font-family: Arial, sans-serif; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">DISCLAIMER </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">SUBSCRIBER EXPRESSLY ACKNOWLEDGES
  AND AGREES THAT; (i) TO THE EXTENT PERMITTED BY APPLICABLE LAW, USE OF THE
  SERVICES IS AT SUBSCRIBER’S SOLE RISK AND THAT THE ENTIRE RISK AS TO
  SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH SUBSCRIBER; (ii)
  INSTALLATION AND USE OF THE SERVICE MAY AFFECT THE USABILITY OF THIRD PARTY
  SOFTWARE, APPLICATIONS OR THIRD PARTY SERVICES; (iii) THAT THE SERVICE IS NOT
  INTENDED OR SUITABLE FOR USE IN SITUATIONS OR ENVIRONMENTS WHERE THE FAILURE OR
  TIME DELAY OF, OR ERRORS OR INACCURACIES IN THE CONTENT, DATA OR INFORMATION
  PROVIDED BY THE SERVICES COULD LEAD TO DEATH, PERSONAL INJURY, OR SEVERE
  PHYSICAL, FINANCIAL OR ENVIRONMENTAL DAMAGE; (iv) USING THE SERVICES FOR
  TRADING SECURITIES IS NOT PERMITTED, AND; (v) SHOULD THE SERVICES<span>&nbsp; </span>PROVE DEFECTIVE, SUBSCRIBER ASSUMES THE
  ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION, IF APPLICABLE. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 12px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">No Warranties
  </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 16px 0px 12px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">To the maximum extent
  permitted by law, The Service is provided to Subscriber "AS IS" and
  "AS AVAILABLE" and with all faults and defects without warranty of
  any kind. To the maximum extent permitted under applicable law, Company, on its
  own behalf and on behalf of its affiliates and its and their respective
  licensors and service providers, expressly disclaims all warranties, whether
  express, implied, statutory or otherwise, with respect to the Service,
  including all implied warranties of merchantability, fitness for a particular
  purpose, satisfactory quality, quiet enjoyment, title and non-infringement, and
  warranties that may arise out of course of dealing, course of performance,
  usage or trade practice. No oral or written information or advice given by Company
  or an approved representative shall create a warranty. Without limitation to
  the foregoing, Company provides no warranty or undertaking, and makes no
  representation of any kind, express or implied: (i) that the Service will meet
  subscriber’s requirements, or achieve any intended results, or against
  interference with subscriber’s enjoyment of the Services; (ii) be compatible or
  work with any other third party software, applications, systems or services;
  (iii) as to the operation or availability of the Service, or the information,
  content, and materials or products included thereon; (iv) that the Service will
  be uninterrupted or error-free, or meet any performance or reliability
  standards; (v) that any update will continue to be made available; (vi) that any
  errors or defects can or will be corrected (vii) as to the accuracy,
  reliability, or currency of any information or content provided through the
  Service; or (viii) that the Service, its servers, the content, or e-mails sent
  from or on behalf of Company are free of viruses, scripts, trojan horses,
  worms, malware, or other harmful components. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 9px 54px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Some jurisdictions do
  not allow the exclusion of or limitations on implied warranties or the
  limitations on the applicable statutory rights of a consumer, so some or all of
  the above exclusions and limitations may not apply. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Privacy and
  Security </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 19px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Company will comply
  with data breach laws and industry standards in the United States of America,
  including but not limited to HIPAA and HITECH. Company and any subcontractors
  to whom Protected Health Information is provided shall maintain a comprehensive
  data security system, which shall include reasonable and appropriate technical,
  organizational and security measures against the destruction, loss,
  unauthorized access or alteration of data in the possession of Company or its
  subcontractors. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 15px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Marketing </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 25px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Company may; (i)
  publish a news release within ninety (90) days from the Effective Date
  regarding Subscriber’s decision to use the Service, the content of which will
  be mutually agreed by Subscriber and Company prior to publication; and (ii)
  include Subscriber’s name and/or logo that Company provides to its then current
  or prospective customers. </span></p>
  
  <p class="MsoNormal" style="margin: 0px; line-height: 115%; font-family: Arial, Helvetica, sans-serif; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;">&nbsp;</span><br></p>
  
  <p class="MsoNormal" style="margin: 0px 0px 0px 13px; line-height: 115%; font-family: Arial, sans-serif; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Account
  Communications </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">By creating an Account
  on our Service, Subscriber certifies that (i) Subscriber or Subscriber’s
  end-users are above the age of 18; (ii) Subscriber will maintain the
  confidentiality of Subscriber’s account username and password, including but
  not limited to the restriction of access to Subscriber’s computer, mobile device,
  and/or account; (iii) Subscriber agrees to accept responsibility for any and
  all activities or actions that occur under Subscriber’s account username and/or
  password, whether Subscriber’s password is with Service or a third-party; (iv)
  Subscriber agrees to receive surveys, informational and marketing materials
  Company may send; (v) Subscriber agrees to notify Company immediately upon
  becoming aware of any breach of security or unauthorized use of Subscribers
  account, and (vi) the information provided to </span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; font-size: 14px;">C</span><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; font-size: 14px;"><span style="color: black; font-family: Arial, Helvetica, sans-serif;">ompany is accurate, complete, and current at
  all times. Inaccurate, incomplete, or obsolete information may result in the
  immediate termination of Subscriber’s account on the Service. </span></span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 15px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Limitation of
  Liability </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 14px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">If, notwithstanding
  the terms herein, Company is found liable to Subscriber for any damage or loss
  which arises out of or is in any way connected with Subscriber’s use of the
  Service, the entire liability of Company and any of its suppliers under any provision
  of this Agreement and Subscriber’s exclusive remedy for all of the foregoing
  shall be limited to the amount actually paid by Subscriber for the Service over
  the prior 12 months, not to exceed $200 US Dollars. To the maximum extent
  permitted by applicable law, in no event shall Company, its officers,
  shareholders, predecessors, successors in interest, directors, employees,
  agents, subsidiaries, affiliates, licensors and suppliers be liable for any
  special, punitive, incidental, indirect, exemplary, or consequential damages
  whatsoever including without limitation, damages for loss of profits, loss of
  data or other information, business interruption, personal injury, loss of
  privacy arising out of or in any way related to the use of or inability to use
  the Service, third-party software and/or third-party hardware used with the
  Service or in connection with any provision of this Agreement, goodwill, or
  other intangible losses, resulting from: (i) your access to or use of or
  inability to access or use the Service; (ii) any conduct or content of any
  third party on the Service; (iii) any content obtained from the Service; and
  (iv) unauthorized access, use or alteration of your transmissions or content;
  even if Company or any supplier has been advised of the possibility of such
  damages or any direct damages and even if the remedy fails of its essential
  purpose. Subscriber assumes total responsibility for the use of the Service.
  Subscriber’s only remedy against Company for dissatisfaction with the Service
  is to stop using the Service.<span>&nbsp; </span>In
  addition to the forgoing limitations of liability, Subscriber agrees that
  Subscriber will not join any claim against Company with the claim of any other
  person or entity in a lawsuit, arbitration or other proceeding; that no claim Subscriber
  has against Company shall be resolved on a class- wide basis; and that
  Subscriber will not assert a claim in a representative capacity against Company
  on behalf of anyone else.<s></s></span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 0px 14px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;">&nbsp;</span><br></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 14px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Some
  states/jurisdictions do not allow the exclusion or limitation of incidental or
  consequential damages, so the above limitation or exclusion may not apply to
  Subscriber. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Severability </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 22px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">If any provision of
  this Agreement is held to be unenforceable or invalid, such provision will be
  changed and interpreted to accomplish the objectives of such provision to the
  greatest extent possible under applicable law and the remaining provisions will
  continue in full force and effect. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 13px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Waiver </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 0px 26px 0px 14px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;">Except as provided
  herein, the failure to exercise a right or to require performance of an
  obligation under this Agreement shall not </span><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; font-size: 14px;">affect<span style="color: black;"> a party's ability to exercise such
  right or require such performance at any time thereafter nor shall be the
  waiver of a breach constitute waiver of any subsequent breach. </span></span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 11px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">For U.S.
  Government End Users </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 21px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">The Service and
  related documentation are "Commercial Items", as that term is defined
  under 48 C.F.R. §2.101, consisting of "Commercial Computer Software"
  and "Commercial Computer Software Documentation", as such terms are
  used under 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. In
  accordance with 48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1</span></p>
  
  <p class="MsoNormal" style="margin: 0px; line-height: 115%; font-family: Arial, Helvetica, sans-serif; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;">&nbsp;</span><br></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 20px 0px 13px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">through 227.7202-4, as applicable,
  the Commercial Computer Software and Commercial Computer Software Documentation
  are being licensed to U.S. Government end users: (i) only as Commercial Items;
  and (ii) with only those rights as are granted to all other end users pursuant
  to the terms and conditions herein. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Export
  Compliance </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 14px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Subscriber may not
  export or re-export the Service software except as authorized by United States
  law and the laws of the jurisdiction in which the Service software was
  obtained. In particular, but without limitation, the Service software may not
  be exported or re-exported: (i) into or to a nation or a resident of any U.S.
  embargoed countries; or (ii) to anyone on the U.S. Treasury Department's list
  of Specially Designated Nationals or the U.S. Department of Commerce Denied
  Person's List or Entity List. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 13px 27px 0px 14px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">By installing or using
  any component of the Service, Subscriber represents and warrants that
  Subscriber is not located in, under control of, or a national or resident of
  any such country or on any such list. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 15px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 17px;">Amendments to this Agreement </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 0px 17px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Company reserves the
  right, at its sole discretion, to modify or replace these Terms at any time. </span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(44, 63, 81); font-size: 14px;">This Agreement may be modified by
  a “click wrap” or “click accept” agreement that Company incorporates into or as
  a condition of downloading Services. Except as expressly provided herein (e.g.,
  with respect to the Privacy Policy), this Agreement may be amended, or any term
  or condition set forth herein waived, only by a writing hand-signed by both
  Parties </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 13px 14px 0px 13px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">By continuing to access or use our
  Service after any revisions become effective, Subscriber agrees to be bound by
  the revised terms. If Subscriber does not agree to the new terms, Subscriber is
  no longer authorized to use the Service. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 13px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Assignment </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 21px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">This Agreement may not
  be assigned or transferred, in whole or in part, without the other party’s
  prior written consent, provided that Company expressly reserves the right to
  assign this Agreement in its entirety to a successor in interest of all or substantially
  all of its business or assets. Any action or conduct in violation of the
  foregoing shall be void and without effect. Subject to the foregoing, all
  rights and obligations of the parties hereunder shall be binding upon and inure
  to the benefit of and be enforceable by and against the successors and
  permitted assigns. Company may delegate any of its obligations hereunder,
  provided that it shall remain fully liable and responsible for its delegates’
  actions or inactions in violation of this Agreement </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Force Majeure
  </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 0px 17px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;">Company shall not be
  liable to the other for failure to perform if said failure results, directly or
  indirectly, from government action or inaction, mechanical or electrical
  breakdown, or natural disaster. In the event Company invokes the Force Majeure,
  the payment provisions in any Subscriber Agreement shall be suspended for an
  equal period of time. If either </span><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; font-size: 14px;">P<span style="color: black;">arty is affected by an interruption or delay contemplated
  by this section, it will: (i) promptly provide notice to the other </span>P<span style="color: black;">arty, explaining the full particulars and the expected
  duration of the such delay and (ii) use its best efforts to remedy the
  interruption or delay if it is reasonably capable of being remedied. </span></span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Notice and
  Contact Information </span></strong></p>
  
  <p class="MsoNormal" style="margin: 0px 0px 0px 13px; line-height: 115%; font-family: Arial, sans-serif; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">If Subscriber has any questions
  about this Agreement, please contact us, at </span><u><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: blue; font-size: 14px;">support@caregem.com. </span></u><span style="font-family: Arial, Helvetica, sans-serif;"><span lang="EN-US" style="line-height: 115%; color: black; font-size: 14px;">Any notice required or permitted to
  be given in accordance with this Agreement shall be in writing. Notices to
  Company shall be sent by personal delivery, registered or certified mail
  (return receipt requested, postage prepaid) or commercial express courier (with
  written verification of receipt) to:</span><span lang="EN-US" style="line-height: 115%; font-size: 14px;"> Althea.ai, 60 Dogwood Lane, Skillman New Jersey 08558,
  U.S.A, a copy of such notice will also be sent to: Robert Montano, Esq., 9229
  Carrisbrook Lane, Brentwood, TN 37027, Legal Counsel</span><span lang="EN-US" style="line-height: 115%; font-size: 12px;">.<span>&nbsp;&nbsp;
  </span></span></span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Attention:
  Counsel of Record. For contractual purposes, Subscriber consents to<span>&nbsp; </span>receive electronic communications from
  Company. Notices sent to Subscriber shall be sent by<span>&nbsp; </span>personal delivery, electronic mail,
  registered or certified mail (return receipt requested, postage prepaid) or
  commercial express courier (with written verification of receipt) to the
  address listed<span>&nbsp; </span>on Subscriber’s account.
  All notices will be deemed given: (i) when delivered personally; (ii) 24 hours
  after electronic mail is sent, unless Company is notified that the email
  address is invalid; (iii) five (5) days after having been sent by registered or
  certified mail (or ten (10) days for international mail); or (iv) one (1) day
  after deposit with a commercial express courier specifying next day delivery
  (or two (2) days for international courier packages specifying 2-day delivery).
  </span><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;">Either </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 0px 18px 0px 13px; text-indent: 1px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; font-size: 14px;">P<span style="color: black;">arty may change its address for receipt of notice by notice
  to the other </span>P</span><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; font-size: 14px;"><span style="color: black; font-family: Arial, Helvetica, sans-serif;">arty in accordance with this
  Section </span></span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Mobile App </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 41px 0px 13px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">The terms and conditions of this
  Agreement apply to Subscriber’s use of the Mobile App. Subscriber agrees the
  availability of the Mobile App is dependent on the mobile application
  marketplace including the Apple App Store (“Apple”), Google Play Marketplace
  (“Google”), (each an “App Store”) but the following additional terms also apply
  to the Mobile App: </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 12px 50px 0px 37px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;"><span>a)<span style="font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Both Subscriber and Company
  acknowledge that the Terms are concluded between Subscriber and Company only,
  and not with App Store, and that Apple or Google is not responsible for the
  Mobile App or the Service;</span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 12px 50px 0px 37px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;"><span>b)<span style="font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">The Mobile App is licensed to
  Subscriber on a limited, non-exclusive, non-transferrable, non- sublicensable
  basis, solely to be used in connection with the Services;</span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 12px 50px 0px 37px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;"><span>c)<span style="font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Subscriber will only use the Mobile
  App in connection with an Apple or Android device that is owned or controlled
  by Subscriber;</span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 12px 50px 0px 37px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;"><span>d)<span style="font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Subscriber acknowledges and agrees
  that each App Store has no obligation whatsoever to furnish any maintenance and
  support services with respect to the Mobile App;</span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 12px 50px 0px 37px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;"><span>e)<span style="font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">In the event of any failure of the
  Mobile App to conform to any applicable warranty, including those implied by
  law, Subscriber may notify App Store of such failure; upon notification, App
  Store’s sole warranty obligation to Subscriber will be to refund to Subscriber
  the purchase price, if any, of the Mobile App paid by Subscriber to App Store;</span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 12px 50px 0px 37px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;"><span>f)<span style="font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Subscriber acknowledges and agrees
  that Company, and not App Store, is responsible for addressing any claims
  Subscriber or any third party may have in relation to the Mobile App;</span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 12px 50px 0px 37px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;"><span>g)<span style="font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Subscriber acknowledges and agrees
  that, in the event of any third party claim that the Mobile App or Subscriber’s
  possession and use of the Mobile App infringes that third party’s intellectual
  property rights, Company, and not App Store, will be responsible for the
  investigation, defense, settlement and discharge of any such infringement
  claim;</span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 12px 50px 0px 37px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;"><span>h)<span style="font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Subscriber represents and warrants
  that he, she or it is not located in a country subject to a U.S. Government
  embargo, or that has been designated by the U.S. Government as a “terrorist
  supporting” country, and that Subscriber is not listed on any U.S. Government
  list of prohibited or restricted parties;</span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; margin: 12px 50px 0px 37px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;"><span>i)<span style="font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Both Subscriber and Company
  acknowledge and agree that, in Subscriber’s use of the Mobile App, Subscriber
  will comply with any applicable third party terms of agreement which may affect
  or be affected by such use; and</span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 12px 50px 0px 37px; text-indent: -24px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">j)&nbsp; &nbsp; Both Subscriber and Company acknowledge and agree that App Store,
  and App Store’s subsidiaries are third party beneficiaries of the Terms, and
  that upon Subscriber’s acceptance of this Agreement, App Store will have the
  right (and will be deemed to have accepted the right) to enforce the Terms
  against Subscriber as the third party beneficiary hereof.<s></s></span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 34px 0px 0px 15px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Links To
  Other Web Sites </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 12px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">The Service may contain links to
  third party web sites or services that are not owned or controlled by Company </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 13px 19px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Company has no control
  over, and assumes no responsibility for the content, privacy policies, or
  practices of any third party web sites or services. Company does not warrant
  the offerings of any of these entities/individuals or their websites. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 13px 25px 0px 14px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Subscriber
  acknowledges and agrees that Company shall not be responsible or liable,
  directly or indirectly, for any damage or loss caused or alleged to be caused
  by or in connection with use of or reliance on any such content, goods or
  services available on or through any such third party web sites or services. </span></p>
  
  <p class="MsoNormal" style="margin: 0px; line-height: 115%; font-family: Arial, Helvetica, sans-serif; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Times, serif; color: black; font-size: 14px;">&nbsp;</span><br></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 37px 0px 14px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Company strongly
  advises Subscriber to read the terms and conditions and privacy policies of any
  third party web sites or services that Subscriber visits. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Entire
  Agreement </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 40px 0px 14px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">The Agreement
  constitutes the entire agreement between Subscriber and Company regarding
  Subscriber’s use of the Service and supersedes all prior and contemporaneous
  written or oral agreements, communications, proposals, representations between
  Subscriber and Company, </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 15px 0px 13px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">relating to subject matter contained
  herein. The Agreement prevails over any pre-printed, conflicting terms of any
  purchase order, ordering document, acknowledgement or confirmation or other
  document issued by Subscriber. Notwithstanding the foregoing, Subscriber may be
  subject to expressly stated additional terms and conditions that apply when
  Subscriber uses or purchase Company's other services, which Company will
  provide to Subscriber at the time of such use or purchase. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 10px 0px 0px 14px; text-align: left; font-size: 15px;"><strong><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: rgb(79, 129, 189); font-size: 19px;">Governing Law
  </span></strong></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 0px 15px 0px 13px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;"><span>&nbsp;</span>This Agreement and the rights and obligations
  of the parties hereunder shall be construed in accordance with and shall be
  governed by the laws of the State of New Jersey excluding its conflict of law
  rules, and applicable federal law. Any party bringing a legal action or
  proceeding against the other party arising out of or relating to this Agreement
  shall bring the legal action or proceeding only in the state or federal courts
  for Somerset County, New Jersey. Each party consents and submits to the
  exclusive jurisdiction and venue of those courts for the purpose of all legal
  actions and proceedings arising out of or relating to this Agreement. Each
  party irrevocably waives (i) any objection that party may have to the venue of
  any such proceeding or legal action brought in those courts, and (ii) any
  defense of inconvenient forum for the maintenance of a proceeding or legal
  action brought in those courts. </span></p>
  
  <p class="MsoNormal" style="line-height: 115%; font-family: Arial, sans-serif; margin: 12px 19px 0px 13px; text-indent: 0px; text-align: left; font-size: 15px;"><span lang="EN-US" style="line-height: 115%; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 14px;">Subscriber’s use of
  the Service may also be subject to other local, state, national, or
  international laws. This Agreement shall not be governed by the United Nations
  Convention on Contracts for the International Sale of Goods. </span></p>`;

  render() {
    return (
      <>
        <Navbar />
        <div className="PrivacyPolicyContainer">
          <div dangerouslySetInnerHTML={{ __html: this.globalVariable }}></div>
        </div>
      </>
    );
  }
}

import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import "./Home.css";
import Carousel from "react-bootstrap/Carousel";
import ContactForm from "./ContactForm";

export default class Home extends Component {
  render() {
    return (
      <div>
        <Carousel indicators={false} controls={false}>
          <Carousel.Item interval={1500}>
            <Jumbotron fluid className="home-jumbotron">
              <Container>
                <Row fluid className="jumbo-row">
                  <Col xs={8} sm={7} md={7} lg={7} className="jumbo-column">
                    <h2>Empowering Coordinated Healthcare</h2>
                    <p>
                      Caregem aims to deliver improved health outcomes, reduced
                      costs, and enhanced patient experience through the power
                      of technology, data science and collaboration.
                    </p>
                  </Col>
                  <Col xs={4} sm={5} md={5} lg={5} />
                </Row>
              </Container>
            </Jumbotron>
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <Jumbotron fluid className="home-jumbotron-althea"></Jumbotron>
          </Carousel.Item>
        </Carousel>
        {/* <Jumbotron fluid className="home-jumbotron">
          <Container>
            <Row fluid className="jumbo-row">
              <Col xs={8} sm={7} md={7} lg={7} className="jumbo-column">
                <h2>Empowering Coordinated Healthcare</h2>
                <p>
                  Caregem aims to deliver improved health outcomes, reduced
                  costs, and enhanced patient experience through the power of
                  technology, data science and collaboration.
                </p>
              </Col>
              <Col xs={4} sm={5} md={5} lg={5} />
            </Row>
          </Container>
        </Jumbotron> */}

        <Container fluid className="home-intro">
          <a className="anchor" id="about" />
          <Row className="intro-row">
            <Col xs={12} sm={12} md={12} lg={1} className="home-col-spacer" />
            <Col xs={12} sm={12} md={12} lg={4} className="home-col">
              <h2 className="h2-right">About</h2>
              <h1 class="aboutHeading">
                <Image
                  src="/assets/img/careGem-about.png"
                  width="320"
                  alt="CareGem"
                  class="about-logo"
                />
              </h1>
              <hr />
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} className="home-col center">
              <p>
                Caregem is a chronic disease management solution that is
                compliant with HIPAA, and enables health care organizations to
                reduce costs, and successfully assume risk in value-based
                contracts.
              </p>
              <p>
                Our cloud based platform leverages changes in patient’s health
                via regular assessments and IoT, personal health information,
                data driven algorithms and collaboration tools to identify and
                drive timely, appropriate interventions. It enables teams to
                collaborate and engage with their patients anytime and anywhere.
                The net result is, reduced hospitalization, improved quality of
                care and better patient experience.
              </p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={1} className="home-col-spacer" />
          </Row>

          <Row>
            <Col xs={12} sm={12} md={2} lg={2} className="home-col-spacer" />
            <Col xs={12} sm={12} md={4} lg={4} className="home-col">
              <a className="anchor" id="mission" />
              <h2>Our Mission</h2>
              <p>
                To connect and inform the health care team and empower patients
                and families using digital technology.
              </p>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="home-col">
              <h2>Our Vision</h2>
              <p>
                Optimize health care by enabling connectivity and data analytics
                to deliver the right care, to the right person, at the right
                time.
              </p>
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="home-col-spacer" />
          </Row>
        </Container>

        <Container fluid className="home-about">
          <Row>
            <Col className="text-center">
              <span className="category-header">
                <u>
                  <strong className="strong-dark">What </strong> we do
                </u>
                <a className="anchor" id="challenges" />
              </span>
              <br />
              <h5 className="text-center">
                Caregem provides Tools to meet the Challenges in Kidney Care
              </h5>
            </Col>
          </Row>

          <Row fluid className="d-flex justify-content-center row-challenges">
            <Col fluid xs={12} sm={12} md={1} lg={1} />
            <Col
              fluid
              xs={12}
              sm={12}
              md={11}
              lg={11}
              className="col-challenges text-left"
            >
              <span className="category-header">
                <h2 className="subheader-challenges">
                  <u>
                    <span className="white">The</span> Challenges
                  </u>
                </h2>
              </span>
            </Col>
          </Row>

          <Row fluid className="d-flex justify-content-center row-challenges">
            <Col fluid xs={12} sm={12} md={1} lg={1} />
            <Col
              fluid
              xs={12}
              sm={12}
              md={5}
              lg={5}
              className="d-flex justify-content-center col-challenges"
            >
              <p>
                <span className="home-subtitles">
                  Opportunities in the management of CKD:
                </span>
                <ul>
                  <li>Late or Non-Detection of Chronic Kidney Disease</li>
                  <li>Rapid disease progression</li>
                  <li>Poor transition to dialysis</li>
                  <li>
                    Frequent hospitalization and emergency department visits
                  </li>
                  <li>Underuse of home dialysis</li>
                </ul>
              </p>
            </Col>
            <Col
              fluid
              xs={12}
              sm={12}
              md={5}
              lg={5}
              className="d-flex justify-content-center col-challenges"
            >
              <Image
                className="home-images-1"
                src="/assets/img/challenges.svg"
                width="300"
                alt="challenges to kidney care image"
              />
            </Col>
            <Col fluid xs={12} sm={12} md={1} lg={1} />
          </Row>

          <a className="anchor" id="address-it" />

          <Row fluid className="d-flex justify-content-center row-challenges">
            <Col fluid xs={12} sm={12} md={6} lg={6} />
            <Col
              fluid
              xs={12}
              sm={12}
              md={5}
              lg={5}
              className="col-challenges text-left"
            >
              <span className="category-header">
                <h2 className="subheader-challenges">
                  <u>
                    <span className="white">Caregem</span> Solution
                  </u>
                </h2>
              </span>
            </Col>
            <Col fluid xs={12} sm={12} md={1} lg={1} />
          </Row>

          <Row fluid className=" d-flex justify-content-center row-challenges">
            <Col
              fluid
              xs={12}
              sm={12}
              md={1}
              lg={1}
              className="home-col-spacer"
            />
            <Col
              fluid
              xs={12}
              sm={12}
              md={5}
              lg={5}
              className="col-challenges text-center"
            >
              <Image
                className="solution-img-1 align-middle"
                src="/assets/img/hexagon-1.svg"
                width="280"
                alt="Caregem patient engagement and risk stratification data image"
              />
            </Col>
            <Col fluid xs={12} sm={12} md={5} lg={5} className="col-challenges">
              <p>
                <span className="home-subtitles">
                  Patient and family engagement tools with rewards and feedback
                  based upon participation.
                </span>

                <ul className="ul-solution">
                  <li>
                    Surveys of quality of life and social determinants of health
                  </li>
                  <li>Symptom reporting driven by system prompts</li>
                  <li>
                    Useful tools for patients and families such as appointment
                    tracking, and connectivity with providers
                  </li>
                  <li>
                    Data-driven insights for customized and provider-vetted
                    education
                  </li>
                  <li>
                    Data driven pertinent guidance to patients for follow up in
                    case of adverse trends
                  </li>
                </ul>
                <span className="home-subtitles">
                  Risk stratification based upon key health data.
                </span>
                <ul className="ul-solution">
                  <li>
                    Targets high risk patients or those with adverse trends for
                    closer monitoring and earlier interventions
                  </li>
                </ul>
                <span className="home-subtitles">
                  Intervene via virtual patient-centered provider networks.
                </span>
                <ul className="ul-solution">
                  <li>
                    Enables contextual real-time data-driven provider
                    collaboration
                  </li>
                  <li>Patient and family involvement in the care process</li>
                </ul>
              </p>
            </Col>
            <Col
              fluid
              xs={12}
              sm={12}
              md={1}
              lg={1}
              className="home-col-spacer"
            />
          </Row>

          <Row fluid className=" d-flex justify-content-center row-challenges">
            <Col xs={12} sm={12} md={1} lg={1} className="home-col-spacer" />
            <Col xs={12} sm={12} md={5} lg={5} className="home-col-solution-2">
              <p>
                <span className="home-subtitles">
                  Identify Gaps in Care to providers using evidence based
                  guidelines.
                </span>
                <br />
                <br />
                <br />
                <span className="home-subtitles">
                  Data-driven analytic tool to provide fluid management
                  decision-support to dialysis unit staff.
                </span>
                <br />
                <br />
                <br />
                <span className="home-subtitles">
                  Easy to use mobile and web-based workflows that can integrate
                  into existing EHRs.
                </span>
              </p>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={5}
              lg={5}
              className="home-col-image text-center"
            >
              <Image
                src="/assets/img/hexagon-2.svg"
                width="280"
                alt="Caregem fluid managment and patient-centric image"
              />
            </Col>
            <Col xs={12} sm={12} md={1} lg={1} className="home-col-spacer" />
          </Row>
        </Container>

        <Container fluid className="home-who-we-are">
          <Row>
            <Col>
              <span className="category-header">
                <a className="anchor" id="who-we-are" />
                <u>
                  <strong className="strong-light">Who </strong> We Are
                </u>
              </span>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={12} lg={2} className="home-col-spacer" />
            <Col xs={12} sm={12} md={12} lg={8} className="home-col">
              <a className="anchor" id="team" />
              <h2>The Team</h2>
              <p>
                Caregem has an Executive Management Team of highly experienced
                professionals. This team oversees and guides operations to meet
                Caregem's mission, vision, and goals.
              </p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={2} className="home-col-spacer" />
          </Row>

          <Row>
            <Col xs={12} sm={12} md={12} lg={2} className="home-col-spacer" />
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="d-flex justify-content-center home-col"
            >
              <Card style={{ width: "20rem" }}>
                <Image
                  variant="top"
                  src="/assets/img/dr-sanjeev-rastogi.png"
                  alt="Dr. Sanjeev Rastogi"
                  rounded
                />
                <Card.Body className="card-body">
                  <Card.Title className="profile">
                    Dr. Sanjeev Rastogi, MD
                    <h6>Founder</h6>
                    <hr />
                  </Card.Title>

                  <Card.Text>
                    <ul>
                      <li>30 years experience as Internist/Nephrologist.</li>
                      <li>
                        Medical director at 2 Davita dialysis units in NW
                        Indiana.
                      </li>
                      <li>
                        MD from University of London, Residency and fellowship
                        at Mayo Clinic.
                      </li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="d-flex justify-content-center home-col"
            >
              <Card style={{ width: "20rem" }}>
                <Image
                  variant="top"
                  src="/assets/img/dr-amita-rastogi.png"
                  alt="Dr. Amita Rastogi, MD"
                  rounded
                />
                <Card.Body className="card-body">
                  <Card.Title className="profile">
                    Dr. Amita Rastogi, MD
                    <h6>Co-founder</h6>
                    <hr />
                  </Card.Title>

                  <Card.Text>
                    <ul>
                      <li>
                        Medical Director, VP Commercial Programs, Signify Health
                        Partners.
                      </li>
                      <li>
                        Nationally Recognized expert in Episodes of Care
                        Payments.
                      </li>
                      <li>30+ years in healthcare.</li>
                      <li>Mayo Clinic trained Cardio-thoracic surgeon.</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={2} className="home-col-spacer" />
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={2} className="home-col-spacer" />

            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="d-flex justify-content-center home-col"
            >
              <Card style={{ width: "20rem" }}>
                <Image
                  variant="top"
                  src="assets/img/Mukesh.png"
                  alt="Mukesh M. Patel"
                  rounded
                />
                <Card.Body>
                  <Card.Title className="profile">
                    Mukesh M. Patel
                    <h6>Strategic Partner</h6>
                    <hr />
                  </Card.Title>

                  <Card.Text>
                    <ul>
                      <li>
                        Entrepreneur CEO | Innovation Strategist | VC+PE |
                        Advisor
                      </li>
                      <li>
                        Professor of Innovation and Entrepreneurship, Management
                        and Global Business
                      </li>
                      <li>
                        25 Years of Business, Legal, and Innovation-Technology
                        Experience.
                      </li>
                      <li>
                        Principal in Angel Groups, Venture Capital and Private
                        Equity Firms
                      </li>
                      <li>
                        Director of the Innovation Lab; CTEC; and the
                        Entrepreneurship Law Clinic
                      </li>
                      <li>
                        Keynote Speaker, International Speaking Circuit, Advisor
                        for Tech-Innovation Incubators and Accelerators;
                        Corporate Executive Education and Training
                      </li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="d-flex justify-content-center home-col"
            >
              <Card style={{ width: "20rem" }}>
                <Image
                  variant="top"
                  src="/assets/img/SanjayMacwan.png"
                  alt="Sanjay Macwan"
                  rounded
                />
                <Card.Body>
                  <Card.Title className="profile">
                    Sanjay Macwan
                    <h6>Technology Advisor</h6>
                    <hr />
                  </Card.Title>

                  <Card.Text>
                    <ul className="profile-text">
                      <li>
                        Chief Information Officer (CIO) and Chief Information
                        Security Officer (CISO) at Vonage
                      </li>
                      <li>Former CTO, NBCUniversal Media Labs</li>
                      <li>
                        Former VP, Research & Development, AT&T Labs; AT&T
                        Foundry; and CTO Technology Council
                      </li>
                      <li>CTO Advisor to Techcouncil Ventures, NJ</li>
                      <li>
                        Adjunct Professor University of Pennsylvania - Emerging
                        Technology Ventures
                      </li>
                      <li>
                        Innovation Executive Fellow - Rutgers University
                        Innovation Labs
                      </li>
                      <li>Member NYU Cyber Fellows Advisory Council</li>
                      <li>Investor / Advisor to startups with 2 exits</li>
                      <li>
                        48 Issued US patents in fields of mobility, cloud, data,
                        information security, multimedia, content, IPTV
                      </li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="d-flex justify-content-center home-col"
            ></Col>
          </Row>
        </Container>

        <Container fluid className="home-partners">
          <a className="anchor" id="partners" />
          <span className="category-header">
            <a className="anchor" id="health-plans" />
            <u>
              <strong className="strong-light">Our </strong>Prospective Partners
            </u>
          </span>
          <Row className="partners-row">
            <Col xs={12} sm={12} md={2} lg={2} className="home-col-spacer" />
            <Col xs={12} sm={12} md={8} lg={8} className="home-col">
              <h2>Employers, Payers, Health Plans</h2>
              <p>
                Give providers the levers for success in risk-bearing contracts.
              </p>
              {/* <p>How we do this?</p> */}
              <Accordion className="partners-accordion">
                <Card>
                  <Card.Header>
                    How we do this?
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      Learn more...
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ul>
                        <li>
                          Help Providers deliver proactive care at the point of
                          service driven by Evidence based Guidelines.
                        </li>
                        <li>
                          Identify at-risk individuals and proactively resolve
                          gaps in health care.
                        </li>
                        <li>
                          Help Patients and their families take ownership of
                          their health conditions.
                        </li>
                        <li>
                          Connect patients and their families with providers
                          through a collaborative network.
                        </li>
                        <ul>
                          <li>Enables proactive reporting of key symptoms.</li>
                          <li>
                            Customized education, vetted by providers and driven
                            by patients unique data.
                          </li>
                        </ul>
                        <li>
                          Enable Provider collaboration through patient-centered
                          provider Networks.
                        </li>
                        <li>Obtain Patient reported outcome measures.</li>
                        <li>
                          Improve member satisfaction with better outcomes and
                          better patient experience.
                        </li>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              {/* <p>
                <ul>
                  <li>
                    Help Providers deliver proactive care at the point of
                    service driven by Evidence based Guidelines
                  </li>
                  <li>
                    Identify at-risk individuals and proactively resolve gaps in
                    health care
                  </li>
                  <li>
                    Help Patients and their families take ownership of their
                    health conditions
                  </li>
                  <li>
                    Connect patients and their families with providers through a
                    collaborative network
                  </li>
                  <ul>
                    <li>Enables proactive reporting of key symptoms</li>
                    <li>
                      Customized education, vetted by providers and driven by
                      patients unique data
                    </li>
                  </ul>
                  <li>
                    Enable Provider collaboration through patient-centered
                    provider Networks
                  </li>
                  <li>Obtain Patient reported outcome measures</li>
                  <li>
                    Improve member satisfaction with better outcomes and better
                    patient experience
                  </li>
                </ul>
              </p> */}
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="home-col-spacer" />
          </Row>

          <Row className="partners-row">
            <Col xs={12} sm={12} md={2} lg={2} className="home-col-spacer" />
            <Col xs={12} sm={12} md={8} lg={8} className="home-col">
              <a className="anchor" id="integrated-care" />
              <h2>Integrated Care Network/ACO</h2>
              <p>
                Realize financial benefits in value-based payment contracts
                through improvements in quality and reduced cost of care.
              </p>
              <Accordion className="partners-accordion">
                <Card>
                  <Card.Header>
                    How we do this?
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      Learn more...
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ul>
                        <li>Risk stratification of patients.</li>
                        <li>
                          Early identification of adverse trends or gaps in
                          care.
                        </li>
                        <li>Patient-specific evidence-based guidelines.</li>
                        <li>
                          Engaging individuals and their families in care
                          decisions.
                        </li>
                        <li>
                          Collaborative network Improving care-transitions
                          across settings.
                        </li>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              {/* <p>
                How we do this?
                <ul>
                  <li>Risk stratification of patients</li>
                  <li>
                    Early identification of adverse trends or gaps in care
                  </li>
                  <li>Patient-specific evidence-based guidelines</li>
                  <li>
                    Engaging individuals and their families in care decisions
                  </li>
                  <li>
                    Collaborative network Improving care-transitions across
                    settings
                  </li>
                </ul>
              </p> */}
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="home-col-spacer" />
          </Row>

          <Row className="partners-row">
            <Col xs={12} sm={12} md={2} lg={2} className="home-col-spacer" />
            <Col xs={12} sm={12} md={8} lg={8} className="home-col">
              <a className="anchor" id="esco" />
              <h2>ESCO</h2>
              <p>
                Enable coordinated, personalized and data-driven care of ESRD
                patients across hospitals, dialysis clinics, vascular access
                centers, and doctors offices, via a collaborative network.
              </p>

              <Accordion className="partners-accordion">
                <Card>
                  <Card.Header>
                    How we do this?
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      Learn more...
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ul>
                        <li>
                          Patient/family engagement via web/mobile application.
                        </li>
                        <li>
                          Regular patient-education customized to specific
                          clinical needs.
                        </li>
                        <li>
                          Proactive symptom tracking to reduce ER visits, and
                          hospitalizations.
                        </li>
                        <li>
                          Risk-stratification based on symptom profile tracking
                          patients functional and medical status.
                        </li>
                        <li>
                          Patient-centered collaborative networks connecting
                          dialysis clinic staff with access surgeons, hospitals,
                          nephrologists, medical specialists, primary care
                          physicians and transplant centers, reducing delays and
                          errors due to miscommunications.
                        </li>
                        <li>
                          Advanced hemodialysis fluid management decision
                          support tool.
                        </li>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              {/* <p>
                How we do this?
                <ul>
                  <li>Patient/family engagement via web/mobile application</li>
                  <li>
                    Regular patient-education customized to specific clinical
                    needs
                  </li>
                  <li>
                    Proactive symptom tracking to reduce ER visits, and
                    hospitalizations
                  </li>
                  <li>
                    Risk-stratification based on symptom profile tracking
                    patients functional and medical status
                  </li>
                  <li>
                    Patient-centered collaborative networks connecting dialysis
                    clinic staff with access surgeons, hospitals, nephrologists,
                    medical specialists, primary care physicians and transplant
                    centers, reducing delays and errors due to miscommunications
                  </li>
                  <li>
                    Advanced hemodialysis fluid management decision support tool
                  </li>
                </ul>
              </p> */}
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="home-col-spacer" />
          </Row>

          <Row className="partners-row">
            <Col xs={12} sm={12} md={2} lg={2} className="home-col-spacer" />
            <Col xs={12} sm={12} md={8} lg={8} className="home-col">
              <a className="anchor" id="providers" />
              <h2>Providers</h2>
              <p>
                Caregem deploys a host of patient and provider tools to succeed
                in risk-bearing contracts and in Advanced Payment Models with
                improved patient outcomes and higher patient satisfaction.
              </p>

              <Accordion className="partners-accordion">
                <Card>
                  <Card.Header>
                    How we do this?
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      Learn more...
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ul>
                        <li>
                          Stratify patients based upon validated data-driven
                          analytics of adverse trends.
                        </li>
                        <li>
                          Target patients at highest risk for admission,
                          re-admission, or progression of CKD.
                        </li>
                        <li>Enable proactive symptom tracking.</li>
                        <li>Access to key data trends at the point of care.</li>
                        <li>
                          Enable delivery of guideline-driven provider-vetted
                          resources to patients and families.
                        </li>
                        <li>
                          Improve satisfaction scores by engaging patients and
                          families in-care process.
                        </li>
                        <li>
                          Increase efficiencies in care through traceable
                          patient-centered provider collaboration.
                        </li>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              {/* <p>
                How we do this?
                <ul>
                  <li>
                    Stratify patients based upon validated data-driven analytics
                    of adverse trends
                  </li>
                  <li>
                    Target patients at highest risk for admission, re-admission,
                    or progression of CKD
                  </li>
                  <li>Enable proactive symptom tracking</li>
                  <li>Access to key data trends at the point of care</li>
                  <li>
                    Enable delivery of guideline-driven provider-vetted
                    resources to patients and families
                  </li>
                  <li>
                    Improve satisfaction scores by engaging patients and
                    families in-care process
                  </li>
                  <li>
                    Increase efficiencies in care through traceable
                    patient-centered provider collaboration
                  </li>
                </ul>
              </p> */}
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="home-col-spacer" />
          </Row>
        </Container>

        <Container fluid className="home-patients">
          <a className="anchor" id="patients" />
          <span className="category-header">
            <u>
              <strong className="strong-light">Our </strong> Patients
            </u>
            <a className="anchor" id="ckd" />
          </span>
          <Row>
            <Col xs={12} sm={12} md={2} lg={2} className="home-col-spacer" />
            <Col xs={12} sm={12} md={8} lg={8} className="home-col">
              <h2>Chronic Kidney Disease</h2>
              <p>
                30 million people in the United States are living with chronic
                kidney disease (CKD).
              </p>
              <p>
                The term “chronic kidney disease” means long term damage to the
                kidneys that often leads to loss of kidney function over time.
                If the damage is progressive and severe, your kidney function
                may worsen to the point that you feel unwell even if you
                continue to make good amount of urine. This may be accompanied
                by symptoms such as nausea, fatigue, poor appetite, swelling,
                shortness of breath, difficulty with sleep, and sometimes
                confusion. This is called kidney failure, and if the changes are
                irreversible it is termed end-stage renal disease (ESRD). If
                your kidneys fail, you will need dialysis or a kidney transplant
                in order to live.
              </p>
              <p>
                Anyone can suffer from CKD. Some people are more at risk than
                others. Some factors that commonly increase your risk for CKD
                include:
                <ul>
                  <li>Diabetes</li>
                  <li>High blood pressure (hypertension)</li>
                  <li>Heart disease</li>
                  <li>Hereditary (family members with kidney disease)</li>
                  <li>
                    Ethnicity (African-American, Hispanic, Native American or
                    Asian)
                  </li>
                  <li>Age (60+) </li>
                  <li>Smoking</li>
                </ul>
              </p>
              <p>
                If you control these risk factors by diet, other lifestyle
                changes, medications and regular follow up supervised by your
                doctors, you may be able to slow or halt the progression of
                chronic kidney disease.
              </p>
              <br />
              <p>
                <h4>How Caregem can help:</h4>
                Caregem connects you and your family to a confidential
                patient-centered virtual network of doctors and nurses that form
                your care team, and collects data from various sources to feed
                an analytic platform which informs your health care team of
                early adverse trends or increased risks of worsening of kidney
                disease. You can report symptoms and receive educational input
                specific to your needs. You have easy access to your health care
                team. You can track and share your appointment schedule with
                your health care team to enable meaningful collaboration between
                team members.
              </p>
              <p>
                Your data is kept strictly confidential in a secure,
                HIPAA-compliant storage, and is shared with your health care
                team. Your data is never sold or marketed.
              </p>
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="home-col-spacer" />
          </Row>
          <ContactForm />
        </Container>
      </div>
    );
  }
}

import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./ContactForm.css";

export default class ContactForm extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      validated: false
    };
  }

  handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validated: true });
  }

  render() {
    const { validated } = this.state;
    return (
      <Container fluid className="home-contact">
        <span className="category-header">
          <u>
            <strong className="strong-wht">Contact </strong> Us
          </u>
          <a className="anchor" id="contact" />
        </span>
        <Row>
          <Col xs={12} sm={12} md={2} lg={2} className="home-col-spacer" />
          <Col xs={12} sm={12} md={8} lg={8} className="home-col">
            <Form
              id="contact-form"
              name="contact-form"
              noValidate
              validated={validated}
              action="contact.php"
              method="POST"
            >
              <Form.Row>
                <Col>
                  <Form.Group controlId="fname">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      id="fname"
                      name="fname"
                      type="text"
                      placeholder="First"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="lname">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      id="lname"
                      name="lname"
                      type="text"
                      placeholder="Last"
                      required
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  id="email"
                  name="email"
                  type="text"
                  placeholder="email@example.com"
                  required
                />
                <Form.Text className="text-muted">
                  We do not share your email with anyone.
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="message">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  id="message"
                  name="message"
                  type="textarea"
                  as="textarea"
                  placeholder="Message"
                  row="6"
                  required
                />
              </Form.Group>

              <Button
                variant="light"
                type="submit"
                onclick="document.getElementById('contact-form').submit();"
                value="Submit"
              >
                Submit
              </Button>
            </Form>
          </Col>
          <Col xs={12} sm={12} md={2} lg={2} className="home-col-spacer" />
        </Row>
      </Container>
    );
  }
}

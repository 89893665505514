import { Navbar } from "react-bootstrap";
import React, { Component } from "react";
import "../components/privacy-policy/PrivacyPolicy.css";

export default class LegalPage extends Component {
  navigateTo = (path) => {
    this.props.history.push(path);
  };
  render() {
    return (
      <>
        <Navbar />
        <div style={{ padding: "20px 100px" }}>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "bolder",
              color: "#005e90",
            }}
          >
            Caregem Privacy Policy and Terms & Conditions
          </p>
          <ul>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => this.navigateTo("/legal/provider/policy")}
            >
              Caregem Privacy Policy
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => this.navigateTo("/legal/provider/terms")}
            >
              Provider Terms and Conditions
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => this.navigateTo("/legal/patient/terms")}
            >
              Patient Terms and Conditions
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => this.navigateTo("/legal/patient/hipaa")}
            >
              Notice of HIPAA Privacy Practices
            </li>
          </ul>
        </div>
      </>
    );
  }
}

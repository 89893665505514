import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import "./PrivacyPolicy.css";

export default class PrivacyPolicy extends Component {
  // constructor(props) {
  //   super(props);
  //   this.scrollRef = React.createRef();
  // }

  // componentDidMount() {
  //   window.addEventListener("scroll", this.handleScroll);
  //   this.scrollToTop();
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("scroll", this.scrollToTop);
  // }

  // handleScroll = () => {
  //   const scrollY = window.scrollY;
  //   const scrollThreshold = 200;

  //   if (scrollY > scrollThreshold) {
  //     this.setState({ showScrollButton: true });
  //   } else {
  //     this.setState({ showScrollButton: false });
  //   }
  // };

  // scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  render() {
    return (
      <>
        <Navbar />
        {/* <div className="PrivacyPolicyContainer" ref={this.scrollRef}> */}
        <div className="PrivacyPolicyContainer">
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicyHeading">
              Terms and Conditions (for Patients and Patient Representatives)
            </p>
            <p className="PrivacyPolicyText">Last updated: April 1, 2021</p>
            <p className="PrivacyPolicyText">
              Please read these Terms and Conditions ("Terms", "Terms and
              Conditions", “Agreement”) carefully before accessing, downloading
              or using the CareGem mobile application (“Mobile App”), the
              CareGem web based administrative enterprise platform, the{" "}
              <a href="http://www.caregem.com/">www.caregem.com</a> website, or
              webpages (together, or individually, the "Service") operated by
              CareGem Health Inc, its successors, assigns or affiliates
              (“Company”, "us", "we", or "our").
            </p>
            <p className="PrivacyPolicyText">
              By accessing, downloading or using the Service, you (“End User”)
              are agreeing to be bound by the terms and conditions of this
              Agreement. The agreement to these terms by Legal Guardian, Power
              of Attorney, Responsible party (“Representative”) acting on behalf
              of an End User will be deemed to be the agreement of that End
              User. Any Representative hereby represents and warrants that he,
              she or it has authority, or has been provided authority, to bind
              the End User to the terms and conditions of this Agreement. Your
              access to and use of the Service is conditioned upon your
              acceptance of and compliance with these Terms. If End User or
              Representative (“Subscriber”) disagrees with any part of the terms
              of this Agreement, Subscriber may not access, download or use the
              Service or and must immediately remove and delete the Service if
              Subscriber has already downloaded this. These Terms apply to all
              visitors, users and others who wish to access or use the Service.
              Company’s acceptance of use by any Subscriber is expressly
              conditional upon Subscriber’s acceptance of these terms to the
              exclusion of all other terms. The validity and enforceability of
              this Agreement does not require Subscriber’s written approval.
            </p>
            <p className="PrivacyPolicyText">
              Notwithstanding anything to the contrary, if Subscriber and
              Company have both executed a written Subscriber Agreement which
              governs access to or use of the Service, then the terms of the
              Subscriber Agreement shall govern and control to the extent there
              is a direct conflict between the terms of this Agreement and the
              terms of the Subscriber Agreement.
            </p>
            <p className="PrivacyPolicyText">
              Each of Subscriber and Company may be referred to herein as a
              “Party,” and together as the “Parties.”
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">License</p>
            <p className="PrivacyPolicyText">
              Company grants Subscriber a revocable, non-exclusive,
              non-transferable, limited license to download, install and use the
              Service strictly in accordance with the terms of this Agreement.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Restrictions</p>
            <p className="PrivacyPolicyText">
              Subscriber agrees not to do, or permit others to do any of the
              following:
            </p>
            <ul style={{ listStyle: "none" }}>
              <span style={{ display: "flex" }}>
                <span>a)&emsp;</span>
                <li>
                  copy, license, resell, sell, rent, lease, sublicense, assign,
                  distribute, transmit, host, outsource, disclose, transfer or
                  make the Service available to any third party;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>b)&emsp;</span>
                <li>
                  modify, adapt, make derivative works of, disassemble, decrypt,
                  reverse compile, hack or reverse engineer, or otherwise
                  attempt to derive the source code for any part of the Service.
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>c)&emsp;</span>
                <li>merge the Service with other software</li>
              </span>
              <span style={{ display: "flex" }}>
                <span>d)&emsp;</span>
                <li>
                  remove, alter or obscure any proprietary notice (including any
                  notice of copyright or trademark) of Company or its
                  affiliates, partners, suppliers or the licensors of the
                  Service;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>e)&emsp;</span>
                <li>
                  use or modify another website so as to falsely imply that it
                  is associated with CareGem;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>f)&emsp;</span>
                <li>
                  use the Service in contravention of any applicable laws, or
                  for any illegal or unauthorized purpose or beyond the scope of
                  the Service’s expected use;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>g)&emsp;</span>
                <li>
                  use the Services to transmit any content that may be deemed
                  unlawful, threatening, racist, abusive, libelous,
                  pornographic, vulgar, defamatory, obscene, or otherwise
                  inappropriate, including any messages constituting or
                  encouraging criminal conduct; content that violates or
                  infringes on Company’s legal rights or copyrights, or the
                  legal rights or copyrights of others
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>h)&emsp;</span>
                <li>
                  intentionally interfere with the operation of the Service or
                  with any other person’s use of the Service;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>i)&emsp;</span>
                <li>
                  intentionally transmit any viruses, bugs, worms, or any other
                  computer code of a destructive nature or that may harm a
                  network, computer, server, hardware, software or telephone
                  equipment using the Service;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>j)&emsp;</span>
                <li>
                  intentionally transmit any viruses, bugs, worms, or any other
                  computer code of a destructive nature or that may harm a
                  network, computer, server, hardware, software or telephone
                  equipment using the Service;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>k)&emsp;</span>
                <li>
                  breach, or attempt to breach, the security of the Service;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>l)&emsp;</span>
                <li>
                  use the Service in excess of or beyond the maximum number of
                  registered users and/or devices that Subscriber has agreed to;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>m)&emsp;</span>
                <li>impose an unreasonable burden on the Service or network</li>
              </span>
              <span style={{ display: "flex" }}>
                <span>n)&emsp;</span>
                <li>
                  use any data mining, robots, or similar data gathering and
                  extraction methods in connection with the Service
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>o)&emsp;</span>
                <li>
                  solicit any activity, unlawful or otherwise, that infringes
                  Company’s rights or the rights of any other party;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>p)&emsp;</span>
                <li>
                  enable others to violate any of these terms and conditions;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>q)&emsp;</span>
                <li>
                  use the Service to create or transmit unwanted email, junk
                  email, bulk email, promotions, spam or content that includes
                  any advertising
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>r)&emsp;</span>
                <li>
                  violate anyone else’s legal rights (e.g. privacy rights) or
                  any laws (e.g. HIPAA, copyright laws) in Subscriber’s
                  jurisdiction while using the Service;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>s)&emsp;</span>
                <li>
                  use or export the Service in violation of U.S. export laws and
                  regulations;
                </li>
              </span>
            </ul>
            <p className="PrivacyPolicyText">
              A violation of any of the Terms by Subscriber will result in the
              termination of Subscriber’s CareGem account and Subscriber’s
              ability to access or use the Service. In addition, Company
              reserves the right to refuse Service to anyone for any reason at
              any time. Company may (but has no obligation to) restrict accounts
              containing content that Company determines (in its sole
              discretion) is unlawful, offensive, threatening, libelous,
              defamatory, obscene or otherwise objectionable. While Company
              prohibits certain conduct and content, Subscriber understands and
              agrees that Company is not responsible for the content transmitted
              between CareGem users. Notwithstanding Company’s governing terms
              and conditions, Subscriber agrees to assume the risk of exposure
              to offensive or unlawful content and to use the Services at
              Subscriber’s own risk.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Subscriber’s Suggestions</p>
            <p className="PrivacyPolicyText">
              Any feedback, comments, ideas, improvements or suggestions
              (collectively, "Suggestions") provided by Subscriber, including
              Subscriber’s employees, contractors or other agents, to Company
              with respect to the Service shall remain the sole and exclusive
              property of Company, and deemed Confidential Information (as
              defined below) of Company, and Subscriber hereby assigns the same
              to Company
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Modifications to Service</p>
            <p className="PrivacyPolicyText">
              Company reserves the right to modify, suspend or discontinue,
              temporarily or permanently, the Service or any service to which it
              connects, with or without notice and without liability of Company
              to Subscriber.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Updates to Service</p>
            <p className="PrivacyPolicyText">
              Company may from time to time provide enhancements or improvements
              to the features/functionality of the Service, which may include
              patches, bug fixes, updates, upgrades and other modifications
              ("Updates").
            </p>
            <p className="PrivacyPolicyText">
              Updates may modify or delete certain features and/or
              functionalities of the Service. Subscriber agrees that Company has
              no obligation to (i) provide any Updates, or (ii) continue to
              provide or enable any particular features and/or functionalities
              of the Service to Subscriber. By using the Service, Subscriber
              grants Company permission to send messages to Subscriber regarding
              the Service, its features, service alerts, and network activity.
              Notwithstanding the foregoing, it is Subscriber’s responsibility
              to register for updates from, and/or regularly check, Company’s
              webpages.
            </p>
            <p className="PrivacyPolicyText">
              Subscriber further agrees that all Updates will be (i) deemed to
              constitute an integral part of the Service, and (ii) subject to
              the terms and conditions of this Agreement. Subscriber’s continued
              use of the Service after such updates will constitute its
              acceptance of the changes.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Third-Party Services</p>
            <p className="PrivacyPolicyText">
              The Service may display, include or make available third-party
              content (including data, information, applications and other
              products services) or provide links to third-party websites or
              services ("Third-Party Services").
            </p>
            <p className="PrivacyPolicyText">
              Subscriber acknowledges and agrees that Company shall not be
              responsible for any Third-Party Services, including their
              accuracy, completeness, timeliness, validity, copyright
              compliance, legality, decency, quality or any other aspect
              thereof. Company does not assume and shall not have any liability
              or responsibility to Subscriber or any other person or entity for
              any Third-Party Services.
            </p>
            <p className="PrivacyPolicyText">
              Third-Party Services and links thereto are provided solely as a
              convenience to Subscriber and Subscriber accesses and uses them
              entirely at Subscriber’s own risk and subject to such third
              parties' terms and conditions.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Content</p>
            <p className="PrivacyPolicyText">
              Service allows Subscriber to post, link, store, share and
              otherwise make available certain information, text, graphics,
              photos, or other material ("Content"). Subscriber acknowledges
              responsibility for; (i) Content posted through the Service,
              including its legality, reliability, and appropriateness; and (ii)
              ensuring the Content does not violate anyone else’s legal rights
              (e.g. privacy rights, publicity rights, copyrights, contract
              rights or any other rights) or any laws (e.g. HIPAA, copyright
              laws) in Subscriber’s jurisdiction while using the Service.
              Subscriber agrees that by posting Content using the Service you
              grant Company the right and license to use the Content to improve
              the Service, to the extent permitted by applicable law.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Privacy Policy</p>
            <p className="PrivacyPolicyText">
              Company collects, stores, maintains, and shares information about
              End Users in accordance with its Privacy Policy, which is
              available at www.caregem.com/legal. By accepting this Agreement,
              Subscriber acknowledges that Subscriber hereby agrees and consents
              to the terms and conditions of our Privacy Policy. The terms and
              conditions set forth in Company’s Privacy Policy may be changed by
              Company in its sole discretion and those changes become effective
              upon posting. It is Subscriber’s responsibility to review the
              Privacy Policy for revisions thereto.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Term and Termination</p>
            <p className="PrivacyPolicyText">
              This Agreement shall be effective as of the date Subscriber
              accepts the terms herein or first accesses, downloads or uses the
              Service (the “Effective Date”) and shall remain in effect for so
              long as Subscriber uses or accesses the Service (the “Agreement
              Term”). The terms herein that contemplate obligations after the
              Agreement Term, including but not limited to Indemnification,
              Disclaimer, Limitation of Liability, Severability, and Governing
              Law and Confidentiality, shall survive termination.
            </p>
            <p className="PrivacyPolicyText">
              This Agreement shall remain in effect until terminated by
              Subscriber or Company. Company may, in its sole discretion, at any
              time and for any or no reason, suspend or terminate this Agreement
              with or without prior notice.
            </p>
            <p className="PrivacyPolicyText">
              This Agreement will terminate immediately, without prior notice
              from Company, in the event that Subscriber fails to comply with
              any provision of this Agreement. Subscriber may also terminate
              this Agreement by deleting the Service software and all copies
              thereof from Subscriber’s mobile device or from Subscriber’s
              computer.
            </p>
            <p className="PrivacyPolicyText">
              Upon termination of this Agreement Term, Subscriber shall no
              longer be permitted to use or access the Service, and Subscriber
              shall cease all further use of the Service and delete all copies
              of the Service software from Subscriber’s mobile device or
              computer. Termination of this Agreement will not limit any of
              Company's rights or remedies at law or in equity in case of breach
              by Subscriber (during the term of this Agreement) of any of
              Subscriber’s obligations under the present Agreement.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">SMS Messages</p>
            <p className="PrivacyPolicyText">
              Use of Service involves transmission of SMS messages for alerts
              and collaboration. Message and data rates may apply, for which
              Subscriber or the End User shall be solely responsible.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Intellectual Propertys</p>
            <p className="PrivacyPolicyText">
              The Service, including without limitation all copyrights, patents,
              trademarks, trade secrets and other intellectual property rights
              are, and shall remain, the sole and exclusive property of Company.
              Subscriber acknowledges that the Service, and its respective
              structures, organization, documentation, software and source code
              constitute valuable intellectual property of Company and are owned
              solely by Company. Company does not grant and Subscriber
              acknowledges that it shall have no right, license or interest in
              any of the patents, copyrights, trademarks, or trade secrets
              owned, used or claimed now or in the future by Company. No title
              to or ownership of the intellectual property contained in any part
              of Service, the software, as well as all enhancements, updates,
              modifications, local versions or any derivatives of the Service or
              the software, or any and all intellectual property and proprietary
              rights therein, or Company’s confidential information is
              transferred to Subscriber. There are no implied licenses granted
              by Company under this Agreement
            </p>
            <p className="PrivacyPolicyText">
              “CareGem” and other CareGem graphics, logos, designs, page
              headers, button icons, scripts and service names are trademarks of
              Company. Company’s trademarks and trade dress, as well as third
              party trademarks, logos and service marks used in conjunction with
              the Service, may not be used in connection with any other product
              or service in any manner that is likely to cause confusion and may
              not be copied, imitated, or used, in whole or in part, without the
              prior written permission of Company.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Indemnification</p>
            <p className="PrivacyPolicyText">
              Subscriber agrees to be solely liable for conduct, acts and
              omissions of Subscriber. Subscriber agrees to indemnify and hold
              Company and its parents, subsidiaries, affiliates, officers,
              shareholders, directors, employees, agents, predecessors,
              successors in interest, partners and licensors (if any) harmless
              from any claim or demand, including reasonable attorneys' fees,
              due to or arising out of Subscriber’s: (i) use and access of the
              Service, including access to Service by anyone using Subscriber’s
              account and password; (ii) content posted on Service; (iii)
              violation of any right of a third party; or (iv) violation of this
              Agreement or any law or regulation
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Confidentiality</p>
            <p className="PrivacyPolicyText">
              “Confidential Information” means any non-public data, information
              and other materials regarding the products, software, services,
              prices and discounts, or business of a party (and/or of third
              parties, to the extent a party is bound to protect the
              confidentiality of any third ’parties’ information) provided by a
              party, its employees, contractors or affiliates (“Disclosing
              Party”) to the other party (“Receiving Party”) where such
              information is marked or otherwise communicated as being
              “proprietary” or “confidential” or the like, or where such
              information should, by its nature, be reasonably considered to be
              confidential and/or proprietary. The parties agree, without
              limiting the foregoing, that any performance and security data,
              product roadmaps, source code, benchmark results, and technical
              information relating to the Services, including pricing
              information, shall be deemed the Confidential Information of
              Company. Notwithstanding the foregoing, Confidential Information
              shall not include information which: (i) is already known to the
              Receiving Party without the obligations of confidentiality prior
              to disclosure by the Disclosing Party; (ii) becomes publicly
              available without fault of the Receiving Party; (iii) is
              rightfully obtained by the Receiving Party from a third party
              without restriction as to disclosure, or is approved for release
              by written authorization of the Disclosing Party; (iv) is
              independently developed or created by the Receiving Party without
              use of the Disclosing Party’s Confidential Information; or (v) is
              required to be disclosed by law or governmental regulation,
              provided that the Receiving Party provides reasonable notice to
              Disclosing Party of such required disclosure to the extent allowed
              by applicable law, and reasonably cooperates with the Disclosing
              Party in limiting such disclosure. Except as expressly authorized
              herein, the Receiving Party shall: (i) use the Confidential
              Information of the Disclosing Party only to perform hereunder or
              exercise rights granted to it hereunder; and (ii) treat all
              Confidential Information of the Disclosing Party in the same
              manner as it treats its own similar proprietary information, but
              in no case will the degree of care be less than reasonable care.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Equitable Relief</p>
            <p className="PrivacyPolicyText">
              The parties agree that a material breach of this Agreement
              adversely affecting Company’s intellectual property rights in the
              Service or the Confidential Information may cause irreparable
              injury to Company for which monetary damages would not be an
              adequate remedy and Company shall be entitled to equitable relief
              (without a requirement to post a bond) in addition to any remedies
              it may have hereunder or at law.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Subscriber Acceptance</p>
            <p className="PrivacyPolicyText">
              Subscriber represents and warrants that Subscriber fully
              understands and acknowledges that: (i) the Service is not intended
              as a substitute for any of Subscriber’s current processes and
              systems of promptly administering and safeguarding medical
              treatment; (ii) the Service is dependent upon factors outside the
              control of Company, including but not limited to, the operation of
              third party network services and hardware; (iii) the Service is
              not intended to deliver critical, emergency messages between
              users; (iv) the Service is not expected to perform at the same
              level of performance and/or reliability required of devices used
              in the delivery of critical medical care environments; and (v)
              there may be occasional communication failures or delays in the
              delivery or receipt of properly sent CareGem messages.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">DISCLAIMER</p>
            <p className="PrivacyPolicyText">
              SUBSCRIBER EXPRESSLY ACKNOWLEDGES AND AGREES THAT; (i) TO THE
              EXTENT PERMITTED BY APPLICABLE LAW, USE OF THE SERVICES IS AT
              SUBSCRIBER’S SOLE RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY
              QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH SUBSCRIBER; (ii)
              INSTALLATION AND USE OF THE SERVICE MAY AFFECT THE USABILITY OF
              THIRD PARTY SOFTWARE, APPLICATIONS OR THIRD PARTY SERVICES; (iii)
              THAT THE SERVICE IS NOT INTENDED OR SUITABLE FOR USE IN SITUATIONS
              OR ENVIRONMENTS WHERE THE FAILURE OR TIME DELAY OF, OR ERRORS OR
              INACCURACIES IN THE CONTENT, DATA OR INFORMATION PROVIDED BY THE
              SERVICES COULD LEAD TO DEATH, PERSONAL INJURY, OR SEVERE PHYSICAL,
              FINANCIAL OR ENVIRONMENTAL DAMAGE; (iv) USING THE SERVICES FOR
              TRADING SECURITIES IS NOT PERMITTED, AND; (v) SHOULD THE SERVICES
              PROVE DEFECTIVE, SUBSCRIBER ASSUMES THE ENTIRE COST OF ALL
              NECESSARY SERVICING, REPAIR OR CORRECTION, IF APPLICABLE.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">No Warranties</p>
            <p className="PrivacyPolicyText">
              To the maximum extent permitted by law, The Service is provided to
              Subscriber "AS IS" and "AS AVAILABLE" and with all faults and
              defects without warranty of any kind. To the maximum extent
              permitted under applicable law, Company, on its own behalf and on
              behalf of its affiliates and its and their respective licensors
              and service providers, expressly disclaims all warranties, whether
              express, implied, statutory or otherwise, with respect to the
              Service, including all implied warranties of merchantability,
              fitness for a particular purpose, satisfactory quality, quiet
              enjoyment, title and non-infringement, and warranties that may
              arise out of course of dealing, course of performance, usage or
              trade practice. No oral or written information or advice given by
              Company or an approved representative shall create a warranty.
              Without limitation to the foregoing, Company provides no warranty
              or undertaking, and makes no representation of any kind, express
              or implied: (i) that the Service will meet subscriber’s
              requirements, or achieve any intended results, or against
              interference with subscriber’s enjoyment of the Services; (ii) be
              compatible or work with any other third party software,
              applications, systems or services; (iii) as to the operation or
              availability of the Service, or the information, content, and
              materials or products included thereon; (iv) that the Service will
              be uninterrupted or error-free, or meet any performance or
              reliability standards; (v) that any update will continue to be
              made available; (vi) that any errors or defects can or will be
              corrected (vii) as to the accuracy, reliability, or currency of
              any information or content provided through the Service; or (viii)
              that the Service, its servers, the content, or e-mails sent from
              or on behalf of Company are free of viruses, scripts, trojan
              horses, worms, malware, or other harmful components.
            </p>
            <p className="PrivacyPolicyText">
              Some jurisdictions do not allow the exclusion of or limitations on
              implied warranties or the limitations on the applicable statutory
              rights of a consumer, so some or all of the above exclusions and
              limitations may not apply.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Privacy and Security</p>
            <p className="PrivacyPolicyText">
              Company will comply with data breach laws and industry standards
              in the United States of America, including but not limited to
              HIPAA and HITECH. Company and any subcontractors to whom Protected
              Health Information is provided shall maintain a comprehensive data
              security system, which shall include reasonable and appropriate
              technical, organizational and security measures against the
              destruction, loss, unauthorized access or alteration of data in
              the possession of Company or its subcontractors.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Account Communications</p>
            <p className="PrivacyPolicyText">
              By creating an Account on our Service, Subscriber certifies that
              (i) Subscriber is above the age of 18; (ii) Subscriber will
              maintain the confidentiality of Subscriber’s account username and
              password, including but not limited to the restriction of access
              to Subscriber’s computer, mobile device, and/or account; (iii)
              Subscriber agrees to accept responsibility for any and all
              activities or actions that occur under Subscriber’s account
              username and/or password, whether Subscriber’s password is with
              Service or a third-party; (iv) Subscriber agrees to receive
              surveys, informational and marketing materials Company may send;
              (v) Subscriber agrees to notify Company immediately upon becoming
              aware of any breach of security or unauthorized use of Subscribers
              account, and (vi) the information provided to Company is accurate,
              complete, and current at all times. Inaccurate, incomplete, or
              obsolete information may result in the immediate termination of
              Subscriber’s account on the Service.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Limitation of Liability</p>
            <p className="PrivacyPolicyText">
              If, notwithstanding the terms herein, Company is found liable to
              Subscriber for any damage or loss which arises out of or is in any
              way connected with Subscriber’s use of the Service, the entire
              liability of Company and any of its suppliers under any provision
              of this Agreement and Subscriber’s exclusive remedy for all of the
              foregoing shall be limited to the amount actually paid by
              Subscriber for the Service over the prior 12 months, not to exceed
              $200 US Dollars. To the maximum extent permitted by applicable
              law, in no event shall Company, its officers, shareholders,
              predecessors, successors in interest, directors, employees,
              agents, subsidiaries, affiliates, licensors and suppliers be
              liable for any special, punitive, incidental, indirect, exemplary,
              or consequential damages whatsoever including without limitation,
              damages for loss of profits, loss of data or other information,
              business interruption, personal injury, loss of privacy arising
              out of or in any way related to the use of or inability to use the
              Service, third-party software and/or third-party hardware used
              with the Service or in connection with any provision of this
              Agreement, goodwill, or other intangible losses, resulting from:
              (i) your access to or use of or inability to access or use the
              Service; (ii) any conduct or content of any third party on the
              Service; (iii) any content obtained from the Service; and (iv)
              unauthorized access, use or alteration of your transmissions or
              content; even if Company or any supplier has been advised of the
              possibility of such damages or any direct damages and even if the
              remedy fails of its essential purpose. Subscriber assumes total
              responsibility for the use of the Service. Subscriber’s only
              remedy against Company for dissatisfaction with the Service is to
              stop using the Service.
            </p>
            <p className="PrivacyPolicyText">
              In addition to the forgoing limitations of liability, Subscriber
              agrees that Subscriber will not join any claim against Company
              with the claim of any other person or entity in a lawsuit,
              arbitration or other proceeding; that no claim Subscriber has
              against Company shall be resolved on a class- wide basis; and that
              Subscriber will not assert a claim in a representative capacity
              against Company on behalf of anyone else.
            </p>
            <p className="PrivacyPolicyText">
              Some states/jurisdictions do not allow the exclusion or limitation
              of incidental or consequential damages, so the above limitation or
              exclusion may not apply to Subscriber.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Severability</p>
            <p className="PrivacyPolicyText">
              If any provision of this Agreement is held to be unenforceable or
              invalid, such provision will be changed and interpreted to
              accomplish the objectives of such provision to the greatest extent{" "}
              possible under applicable law and the remaining provisions will
              continue in full force and effect.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Waiver</p>
            <p className="PrivacyPolicyText">
              Except as provided herein, the failure to exercise a right or to
              require performance of an obligation under this Agreement shall
              not effect a party's ability to exercise such right or require
              such performance at any time thereafter nor shall be the waiver of
              a breach constitute waiver of any subsequent breach.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Export Compliance</p>
            <p className="PrivacyPolicyText">
              Subscriber may not export or re-export the Service software except
              as authorized by United States law and the laws of the
              jurisdiction in which the Service software was obtained. In
              particular, but without limitation, the Service software may not
              be exported or re-exported: (i) into or to a nation or a resident
              of any U.S. embargoed countries; or (ii) to anyone on the U.S.
              Treasury Department's list of Specially Designated Nationals or
              the U.S. Department of Commerce Denied Person's List or Entity
              List. By installing or using any component of the Service,
              Subscriber represents and warrants that Subscriber is not located
              in, under control of, or a national or resident of any such
              country or on any such list.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">
              Amendments to this Agreement
            </p>
            <p className="PrivacyPolicyText">
              Company reserves the right, at its sole discretion, to modify or
              replace these Terms at any time. This Agreement may be modified by
              a “click wrap” or “click accept” agreement that Company
              incorporates into or as a condition of downloading Services.
              Except as expressly provided herein (e.g., with respect to the
              Privacy Policy), this Agreement may be amended, or any term or
              condition set forth herein waived, only by a writing hand-signed
              by both parties By continuing to access or use our Service after
              any revisions become effective, Subscriber agrees to be bound by
              the revised terms. If Subscriber does not agree to the new terms,
              Subscriber is no longer authorized to use the Service.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Assignment</p>
            <p className="PrivacyPolicyText">
              This Agreement may not be assigned or transferred, in whole or in
              part, without the other party’s prior written consent, provided
              that Company expressly reserves the right to assign this Agreement
              in its entirety to a successor in interest of all or substantially
              all of its business or assets. Any action or conduct in violation
              of the foregoing shall be void and without effect. Subject to the
              foregoing, all rights and obligations of the parties hereunder
              shall be binding upon and inure to the benefit of and be
              enforceable by and against the successors and permitted assigns.
              Company may delegate any of its obligations hereunder, provided
              that it shall remain fully liable and responsible for its
              delegates’ actions or inactions in violation of this Agreement
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Force Majeure</p>
            <p className="PrivacyPolicyText">
              Company shall not be liable to the other for failure to perform if
              said failure results, directly or indirectly, from government
              action or inaction, mechanical or electrical breakdown, or natural
              disaster. In the event Company invokes the Force Majeure, the
              payment provisions in any Subscriber Agreement shall be suspended
              for an equal period of time. If either party is affected by an
              interruption or delay contemplated by this section, it will: (i)
              promptly provide notice to the other party, explaining the full
              particulars and the expected duration of the such delay and (ii)
              use its best efforts to remedy the interruption or delay if it is
              reasonably capable of being remedied.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">
              Notice and Contact Information
            </p>
            <p className="PrivacyPolicyText">
              If Subscriber has any questions about this Agreement, please
              contact us, at{" "}
              <a href="mailto:support@caregem.com.">support@caregem.com</a>. Any
              notice required or permitted to be given in accordance with this
              Agreement shall be in writing. Notices to Company shall be sent by
              personal delivery, registered or certified mail (return receipt
              requested, postage prepaid) or commercial express courier (with
              written verification of receipt) to: CareGem Health Inc., 10326
              Sandy Lane, Munster, Indiana 46321, U.S.A., Attention: Counsel of
              Record. For contractual purposes, Subscriber consents to receive
              electronic communications from Company. Notices sent to Subscriber
              shall be sent by personal delivery, electronic mail, registered or
              certified mail (return receipt requested, postage prepaid) or
              commercial express courier (with written verification of receipt)
              to the address listed on Subscriber’s account. All notices will be
              deemed given: (i) when delivered personally; (ii) 24 hours after
              electronic mail is sent, unless Company is notified that the email
              address is invalid; (iii) five (5) days after having been sent by
              registered or certified mail (or ten (10) days for international
              mail); or (iv) one (1) day after deposit with a commercial express
              courier specifying next day delivery (or two (2) days for
              international courier packages specifying 2-day delivery). Either
              party may change its address for receipt of notice by notice to
              the other party in accordance with this Section
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Mobile App</p>
            <p className="PrivacyPolicyText">
              The terms and conditions of this Agreement apply to Subscriber’s
              use of the Mobile App. Subscriber agrees the availability of the
              Mobile App is dependent on the mobile application marketplace
              including the Apple App Store (“Apple”), Google Play Marketplace
              (“Google”), (each an “App Store”) but the following additional
              terms also apply to the Mobile App:
            </p>
            <ul style={{ listStyle: "none" }}>
              <span style={{ display: "flex" }}>
                <span>a)&emsp;</span>
                <li>
                  Both Subscriber and Company acknowledge that the Terms are
                  concluded between Subscriber and Company only, and not with
                  App Store, and that Apple or Google is not responsible for the
                  Mobile App or the Service;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>b)&emsp;</span>
                <li>
                  The Mobile App is licensed to Subscriber on a limited,
                  non-exclusive, non-transferrable, non- sublicensable basis,
                  solely to be used in connection with the Services;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>c)&emsp;</span>
                <li>
                  Subscriber will only use the Mobile App in connection with an
                  Apple or Android device that is owned or controlled by
                  Subscriber;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>d)&emsp;</span>
                <li>
                  Subscriber acknowledges and agrees that each App Store has no
                  obligation whatsoever to furnish any maintenance and support
                  services with respect to the Mobile App;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>e)&emsp;</span>
                <li>
                  In the event of any failure of the Mobile App to conform to
                  any applicable warranty, including those implied by law,
                  Subscriber may notify App Store of such failure; upon
                  notification, App Store’s sole warranty obligation to
                  Subscriber will be to refund to Subscriber the purchase price,
                  if any, of the Mobile App paid by Subscriber to App Store;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>f)&emsp;</span>
                <li>
                  Subscriber acknowledges and agrees that Company, and not App
                  Store, is responsible for addressing any claims Subscriber or
                  any third party may have in relation to the Mobile App;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>g)&emsp;</span>
                <li>
                  Subscriber acknowledges and agrees that, in the event of any
                  third party claim that the Mobile App or Subscriber’s
                  possession and use of the Mobile App infringes that third
                  party’s intellectual property rights, Company, and not App
                  Store, will be responsible for the investigation, defense,
                  settlement and discharge of any such infringement claim;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>h)&emsp;</span>
                <li>
                  Subscriber represents and warrants that he, she or it is not
                  located in a country subject to a U.S. Government embargo, or
                  that has been designated by the U.S. Government as a
                  “terrorist supporting” country, and that Subscriber is not
                  listed on any U.S. Government list of prohibited or restricted
                  parties;
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>i)&emsp;</span>
                <li>
                  Both Subscriber and Company acknowledge and agree that, in
                  Subscriber’s use of the Mobile App, Subscriber will comply
                  with any applicable third party terms of agreement which may
                  affect or be affected by such use; and
                </li>
              </span>
              <span style={{ display: "flex" }}>
                <span>j)&emsp;</span>
                <li>
                  Both Subscriber and Company acknowledge and agree that App
                  Store, and App Store’s subsidiaries are third party
                  beneficiaries of the Terms, and that upon Subscriber’s
                  acceptance of this Agreement, App Store will have the right
                  (and will be deemed to have accepted the right) to enforce the
                  Terms against Subscriber as the third party beneficiary
                  hereof.
                </li>
              </span>
            </ul>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Links To Other Web Sites</p>
            <p className="PrivacyPolicyText">
              The Service may contain links to third party web sites or services
              that are not owned or controlled by Company
              <br /> Company has no control over, and assumes no responsibility
              for the content, privacy policies, or practices of any third party
              web sites or services. Company does not warrant the offerings of
              any of these entities/individuals or their websites.
              <br /> Subscriber acknowledges and agrees that Company shall not
              be responsible or liable, directly or indirectly, for any damage
              or loss caused or alleged to be caused by or in connection with
              use of or reliance on any such content, goods or services
              available on or through any such third party web sites or
              services.
              <br /> Company strongly advises Subscriber to read the terms and
              conditions and privacy policies of any third party web sites or
              services that Subscriber visits.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Entire Agreement</p>
            <p className="PrivacyPolicyText">
              The Agreement constitutes the entire agreement between Subscriber
              and Company regarding Subscriber’s use of the Service and
              supersedes all prior and contemporaneous written or oral
              agreements, communications, proposals, representations between
              Subscriber and Company, relating to subject matter contained
              herein. The Agreement prevails over any pre-printed, conflicting
              terms of any purchase order, ordering document, acknowledgement or
              confirmation or other document issued by Subscriber.
              Notwithstanding the foregoing, Subscriber may be subject to
              expressly stated additional terms and conditions that apply when
              Subscriber uses or purchase Company's other services, which
              Company will provide to Subscriber at the time of such use or
              purchase.
            </p>
          </div>
          <div className="PrivacyPolicyContent">
            <p className="PrivacyPolicySubHeading">Governing Law</p>
            <p className="PrivacyPolicyText">
              The laws of Indiana, United States, excluding its conflicts of law
              rules, shall govern this Agreement and Subscriber’s use of the
              Service. Each party irrevocably agrees that any claim brought by
              it in any way arising out of this Agreement must be brought solely
              and exclusively in state or federal court located in Lake County,
              Indiana, and each party irrevocably accepts and submits to the
              sole and exclusive jurisdiction of each of the aforesaid courts in
              personam, generally and unconditionally, with respect to any
              action, suit, or proceeding brought by it or against it by the
              other party.
            </p>
            <p className="PrivacyPolicyText">
              Subscriber’s use of the Service may also be subject to other
              local, state, national, or international laws. This Agreement
              shall not be governed by the United Nations Convention on
              Contracts for the International Sale of Goods.
            </p>
          </div>
        </div>
      </>
    );
  }
}
